
function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    // 判断是否在微信中打开
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}

function renderResize() {
    // 横屏
    let basesZoom = 375 / 667; // 标准比例
    let clientWidth = window.document.documentElement.clientWidth;
    let clientHeight = window.document.documentElement.clientHeight;
    let docEl = document.documentElement; // 获取 html 节点
    let transefromRemHandle = function (preWidth) {
        let fontEl = document.createElement("style");
        let base = 100;
        return function () {
            if (isWeiXin()) {
                clientHeight = clientHeight + 64;
            }
            let domStantd = clientWidth / clientHeight; // 当前屏幕比例
            let zoom = basesZoom / domStantd; // 标准比例除当前屏幕比例
            let rem;
            docEl.setAttribute("data-rem", rem);
            if (zoom > 1) { // 标准比例除当前屏幕比例大于1按宽度计算rem
                rem = (clientWidth / preWidth) * base;
                fontEl.innerHTML = "html{font-size:" + rem + "px!important;}";
                docEl.setAttribute("data-rem", rem);
            } else { // 标准比例除当前屏幕比例小于1按高度计算rem
                rem = (clientHeight / 1334) * base;
                fontEl.innerHTML = "html{font-size:" + rem + "px!important;}";
                docEl.setAttribute("data-rem", rem);
            }
            docEl.firstElementChild && docEl.firstElementChild.appendChild(fontEl);
        };
    };
    let num = 750;
    transefromRemHandle(num)();
    let windowResize = window.onresize;
    window.onresize = function (e) {
        windowResize != null && windowResize.bind(this);
        transefromRemHandle(num)();
    };
}
try {
    renderResize();
    // window.addEventListener("resize", renderResize, false)
} catch (e) {
     // 
}