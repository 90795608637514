<template>
  <div :class="[isMobile?'navMain2':'navMain',{'navActive':isScrollTop}]">
    <!-- 顶部栏 -->
    <div class="nav between v_center wrap" v-if="!isMobile">
      <!-- logo -->
      <img src="../../website/assets/img/logobai.png" v-show="isImg1" alt="" />
      <img src="../../website/assets/img/logohei.png" v-show="isImg2" alt="" />
      <div id="nav" class="relative">
        <span id="idea" ref="idea" @click="navHear(0)">理念</span>
        <span id="home" ref="home" @click="navHear(1)">App</span>
        <span id="search" ref="search" @click="navHear(2)">搜人</span>
        <span id="recharge" ref="recharge" @click="navHear(3)">芯片</span>
        <span id="txc" ref="txc" @click="gotxc()">社区</span>
        <!-- <img class="audioImg" src="../../website/assets/img/icon_play.png" v-show="isAudio && types == 2" alt=""
          @click="audioClick(false)" />
        <img class="audioImg" src="../../website/assets/img/icon_muted.png" v-show="!isAudio && types == 2" alt=""
          @click="audioClick(true)" /> -->
        <i class="navHr" ref="navHr" id="navHr"></i>
      </div>
    </div>
    <!-- 顶部栏 -->
    <div class="subNav v_center between" ref="subNav" v-if="isImgBtn1 && isMobile">
      <!-- <img class="logoImg" src="../../website/assets/img/logobai.png" v-show="isImg1" alt="" @click="ImgBtn" /> -->
      <img class="logoImg" src="../../website/assets/img/logohei.png" alt="" @click="ImgBtn" />

      <!-- <img src="../../website/assets/img/组 4852.png" alt="" v-if="isImg1" class="btn" ref="close" @click="ImgBtn"/> -->
      <img src="../../website/assets/img/组 4852 (1).svg" alt="" class="btn" ref="close" @click="ImgBtn" />
    </div>
    <div class="subNav v_center between subNavShow" @click="ImgBtn" ref="subNav" v-if="isImgBtn2 && isMobile">
      <!-- <img class="logoImg" src="../../website/assets/img/logobai.png" v-show="isImg1" alt="" /> -->
      <img class="logoImg" src="../../website/assets/img/logohei.png" alt="" />
      <img src="../../website/assets/img/组 4854.svg" alt="" v-if="isImgBtn" class="btn" />
    </div>
    <div class="subNavContent v" ref="subNavContent" v-if="isMobile">
      <span id="idea" ref="idea" @click="navHear(0)">理念</span>
      <span id="home" ref="home" @click="navHear(1)">App</span>
      <span id="search" ref="search" @click="navHear(2)">搜人</span>
      <span id="recharge" ref="recharge" @click="navHear(3)">芯片</span>
      <span id="txc" ref="txc" @click="gotxc()">社区</span>
    </div>

    <!-- 遮罩层 -->
    <div class="mask" ref="mask" @click="maskShow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAudio: false, //声音
      isImg1: false,
      isImg2: true,
      isImgBtn: false,
      isImgBtn1: true,
      isImgBtn2: false,
    };
  },
  mounted() {
    if(this.types == 2){
      this.isImg1 = true
      this.isImg2 = false
    }
  },
  methods: {
    gotxc() {
      window.open("https://support.qq.com/product/525755");
    },
    audioClick(types) {
      this.isAudio = types;
      console.log(this.isAudio);
      if(this.types == 2){
        this.$emit('audioClick',this.isAudio)
      }
    },
    //监听导航按钮
    navHear(e) {
      if(e == 0){
        this.$router.replace('/view');
      }else if(e == 1){
        this.$router.replace('/');
      }else if(e == 2){
        this.$router.replace('/search');
      }else if(e == 3){
        this.$router.replace('/chip');
      }
    },
    imgJudge() {
      if (this.activeIndex != 0) {
        // this.isImg1 = false;
        // this.isImg2 = true;
        // this.$refs.subNav.style.backgroundColor = "#fff";
        let navSpan = document.querySelectorAll("#nav>span");
        navSpan.forEach((item) => {
          item.style.color = "#31345C";
        });
        // this.$refs.navHr.style.backgroundColor = "#FFE600";
      } else {
        // this.isImg1 = true;
        // this.isImg2 = false;
        this.$refs.subNav.style.backgroundColor = "";

        let navSpan = document.querySelectorAll("#nav>span");
        navSpan.forEach((item) => {
          item.style.color = "#fff";
        });
        // this.$refs.navHr.style.backgroundColor = "#fff";
      }
      //捕获图片
      if (this.isImgBtn) {
        // this.isImg1 = false;
        // this.isImg2 = true;
      } else {
        this.isImgBtn1 = true;
        this.isImgBtn2 = false;
      }
    },
    //选项卡
    ImgBtn() {
      if (!this.isImgBtn) {
        this.ImgBtnFalse();
      } else {
        this.ImgBtnTrue();
      }
    },
    maskShow() {
      this.isImgBtn = false;
      this.ImgBtnTrue();
    },
    //ImgBtn   False
    ImgBtnFalse() {
      this.$refs.mask.style.display = "block";
      this.isImgBtn = true;
      this.$refs.subNavContent.style.top = "1.5rem";
      this.isImgBtn1 = false;
      this.isImgBtn2 = true;
      // this.isImg1 = false;
      // this.isImg2 = true;
    },
    //ImgBtn   True
    ImgBtnTrue() {
      this.$refs.mask.style.display = "none";
      this.isImgBtn = false;
      this.$refs.subNavContent.style.top = "-7rem";
      this.isImgBtn1 = true;
      this.isImgBtn2 = false;
      this.imgJudge();
    },
  },
  props: ["isMobile","types","isScrollTop"],
};
</script>

<style lang="scss" scoped>
//遮罩层
.mask {
  @include scss_wh(100vw, 100vh);
  background: rgba(0, 0, 0, 0.2);
  @include scss_plt(fixed, 0, 0);
  z-index: 4;
  display: none;
}

//副导航
.subNav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 2s;
  cursor: pointer;
  width: 100vw;
  height:1.5rem;
  @include scss_plt(fixed, 0, 0);
  z-index: 99999;
  padding: 0 5.5%;
  .btn {
    @include scss_wh(0.4rem, 0.4rem);
  }
  .logoImg {
    @include scss_wh(2.34rem, 0.64rem);
  }
}

.subNavContent {
  z-index: 199;
  @include scss_plt(fixed, 0,-7rem);
  @include scss_wh(100%, 5.5rem);
  transition: all 1s;

  span {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding-left: 0.5rem;
    @include scss_wh(100%, 1.1rem);
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 0.3rem;
  }
}

//导航
.nav {
  img {
    width: 117px;
  }
  @include scss_wh(78.3%, 56px);
  max-width: 1128px;
  @include scss_plt(fixed,50%,0);
  z-index: 9999;
  transform: translateX(-50%);
  transition: all 1s;
  i {
    display: block;
    @include scss_whbb(25px, 3px,#FFE600,2px);
    transition: all 1s;
  }

  span {
    @include scss_fch(14px, #7A7A86,21px);
    cursor: pointer;
    transition: all 1s;
  }

  span {
    margin-right: 56px;
  }

  span:nth-child(5) {
    margin-right: 0 !important;
  }

  .audioImg {
    @include scss_wh(42px, 42px);
    line-height: initial;
    cursor: pointer;
    margin-top: -12px;
    margin-left: 37px;
  }
}
.navMain{
  @include scss_wh(100%, 56px);
  @include scss_plt(fixed,0,0);
  z-index: 9999;
}
.navMain2{
  @include scss_wh(100%, 1.32rem);
  @include scss_plt(fixed,0,0);
  z-index: 9999;
}
.navActive{
  background:#fff;
}
.subNavShow {
  background-color: #fff;
}
</style>