<template>
  <div :class="[isMobile?'subFooter':'footer']">
    <div class="footerCon" v-if="!isMobile">
      <div class="conl">
        ©&nbsp;&nbsp;2021&nbsp;-&nbsp;2023&nbsp;武汉市更好创新科技有限公司
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备16003430号-10</a>&nbsp;&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备16003430号-13A</a>
      </div>
      <div class="conr">
        <span>联系我们：<a target="_blank" href="https://role.cn/pi">Role.cn/pi</a>&nbsp;&nbsp;400-7653-277</span>
      </div>
    </div>
    <div class="footerCon" v-if="isMobile">
      <div class="conl">
        ©&nbsp;&nbsp;2021&nbsp;-&nbsp;2023&nbsp;武汉市更好创新科技有限公司
      </div>
      <div class="conr">
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备16003430号-10</a>&nbsp;&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备16003430号-13A</a>
      </div>
      <div class="links">
        <span>联系我们：<a target="_blank" href="https://role.cn/pi">Role.cn/pi</a>&nbsp;&nbsp;</span>
        <a href="tel:400-7653-277">400-7653-277</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>
.footer {
  @include scss_wh(77.9%, 105px);
  max-width: 1128px;
  margin: 0 auto;
  border-top: solid 1px #E2E2E5;
  .footerCon{
    @include scss_wh(100%, 100%);
    @include scss_flex_h(row);
    justify-content: space-between;
    align-items: center;
    .conl {
      @include scss_fch(14px, #121127, 29px);

      a {
        color: #444BD3;
      }
    }

    .conr {
      @include scss_flex_h(row);
      span {
        @include scss_fch(14px, #121127);
        @include scss_flex_h(row);
        align-items: center;
        a {
          color: #444BD3;
        }
        img {
          @include scss_wh(24px, 24px);
          margin-right: 4px;
        }
      }

      .tel {
        margin-right: 40px;
      }
    }
  }
}

.subFooter {
  @include scss_wh(100%, 1.36rem);
  border-top: solid 1px #E2E2E5;
  .footerCon{
    @include scss_wh(100%, 100%);
    @include scss_flex_h(column);
    align-items: center; 
    justify-content: center;
    .conl{
      @include scss_fch(0.2rem, rgba(18, 17, 39, 0.56), 0.43rem);
    }
    .conr{
      @include scss_fch(0.2rem, rgba(18, 17, 39, 0.56), 0.43rem);
      a{
        color: #444BD3;
      }
    }
    .links{
      @include scss_fch(0.2rem, rgba(18, 17, 39, 0.56), 0.43rem);
      a{
        color: #444BD3;
      }
    }
  }
}
</style>