<template>
  <!-- 同意数据库 -->
  <div id="index" :class="[isMobile?'database2':'database']">
    <div class="databaseCon relative wrap">
      <div class="swiper-container" v-show="isSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide h_v_center">
              <img class="img1" src="../../website/assets/img/database/img1.png" alt=""/>
            </div>
            <div class="swiper-slide h_v_center">
              <img class="img2" src="../../website/assets/img/database/img2.png" alt=""/>
            </div>
            <div class="swiper-slide h_v_center">
              <img class="img3" src="../../website/assets/img/database/img3.png" alt=""/>
            </div>
            <div class="swiper-slide h_v_center">
              <img class="img4" src="../../website/assets/img/database/img4.png" alt=""/>
            </div>
            <div class="swiper-slide h_v_center">
              <img class="img5" src="../../website/assets/img/database/img5.png" alt=""/>
            </div>
            <div class="swiper-slide h_v_center">
              <img class="img6" src="../../website/assets/img/database/img6.png" alt=""/>
            </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>

      <div class="dataR">
        <div class="titles">
          <span>听我们吹牛</span>
          <span>不如下载，Let's Role</span>
        </div>
        <div class="sub">
          <p>能大幅度降低连接成本，提升连接效率的</p>
          <p>才是真正的Web3</p>
        </div>
        <div class="btns relative h_v_center" @click="dow()">
          哇，我要下载
          <img src="../../website/assets/img/database/right.svg" alt=""/>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  data() {
    return {
      isImg: true,
      isSwiper:false,
    };
  },
  mounted() {
    this.isSwiper = true
    this.mySwiperfunc();
  },
  destroyed(){
    this.isSwiper = false
  },
  methods: {
    //Swiper组件
    mySwiperfunc() {
      this.mySwiper = new Swiper(".swiper-container", {
        observer: true, //开启动态检查器，监测swiper和slide
        loop: false,
        direction: "horizontal",
        // 鼠标可滑动
        mousewheelControl: false,
        // 如果需要分页器
        pagination : {
          el: '.swiper-pagination',
          type: 'bullets',
        }
      });
    },
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>

.database2{
  @include scss_wh(100%, auto);
  .databaseCon{
    width: 89.3vw;
    margin: 0.05rem auto 1.28rem;
    @include scss_flex_h(column);
    .dataR{
      @include scss_wh(100%, auto);
      margin-top: 0.2rem;
      .titles{
        @include scss_fcwh(0.48rem, #121127, 0.72rem);
        margin-bottom: 0.32rem;
        span{
          display: block;
        }
      }
      .sub{
        margin-bottom: 0.21rem;
        p{
          @include scss_fch(0.26rem, rgba(18, 17, 39, 0.56), 0.57rem);
        }
      }
      .btns{
        @include scss_whbb(100%, 1.04rem,#FFE600,0.16rem);
        @include scss_fcwh(0.32rem, #000, 0.46rem);
        cursor: pointer;
        img{
          @include scss_wh(0.4rem, 0.4rem);
          margin-left: 0.25rem;
        }
      }
    }
  }
  ::v-deep .swiper-container{
    @include scss_wh(89.3vw, 10.36rem);
    margin: 0;
    .swiper-slide{
      width: 89.3vw !important;
    }
    .swiper-pagination-bullets{
      bottom: 0.9rem;
      .swiper-pagination-bullet{
        @include scss_whbb(0.2rem, 0.2rem,none,0.1rem);
        border: solid 0.02rem #000;
      }
      .swiper-pagination-bullet-active{
        @include scss_whbb(0.2rem, 0.2rem,#000,0.1rem);
      }
    }
    img{
      width: 120%;
      margin: 0 auto;
      display: block;
    }
  }
}
.database{
  @include scss_wh(100%, auto);
  .databaseCon{
    width: 74.7%;
    max-width: 1076px;
    margin: 33px auto 30px;
    @include scss_flex_h(row);
    justify-content: space-between;
    .dataR{
      @include scss_wh(42.1%, auto);
      margin-top: 166px;
      .titles{
        @include scss_fcwh(40px, #121127, 56px);
        margin-bottom: 20px;
        span{
          display: block;
        }
      }
      .sub{
        margin-bottom: 16px;
        p{
          @include scss_fch(14px, rgba(18, 17, 39, 0.56), 32px);
        }
      }
      .btns{
        @include scss_whbb(192px, 52px,#FFE600,8px);
        @include scss_fcwh(16px, #000, 23px);
        cursor: pointer;
      }
    }
  }
  ::v-deep .swiper-container{
    @include scss_wh(45.6%, 622px);
    margin: 0;
    img{
      width: 110%;
      margin: 0 auto 0;
      display: block;
    }
    .img1{
      margin-left: 22px;
    }
    .img2{
      margin-left: 2px;
    }
    .img3{
      margin-left: 4px;
    }
    .img4{
      margin-left: 2px;
    }
    .img5{
      margin-left: 2px;
    }
    .img6{
      margin-left: 30px;
    }
    .swiper-pagination{
      bottom: 63px;
    }
  }
}
</style>