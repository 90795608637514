<template>
  <!-- 引导页 -->
  <div id="Guide" class="tlrb" :class="[isMobile?'guide2':'guide']">
    <div class="titlesCon">
      <div class="titles1 h_center">TALK IS CHEAP，HERE'S OUR PRODUCT</div>
      <div class="titles2 h_center">关于如尔App</div>
      <div class="titles3 h_center">可能是唯一逻辑自洽且能落地的Web3实现路径</div>
    </div>
    <div class="search relative">
      <div class="searchL">
        <div class="titles">如尔身份地址</div>
        <div class="tips">
          <span>Web3时代数据流通的载体，可以授权不同的数据生成不同的身份地址，</span>
          <span>设定不同的检索规则和价格，用于不同的需求场景。</span>
        </div>
      </div>
      <div class="searchR">
        <div class="inputCon">
          <div class="inputLeft relative h v_center">
            <span>Role.cn/</span>
            <input
              v-model="inputText"
              type="text"
              name=""
              id="inputText"
              class="v_center"
              :class="[inputText.length > 0 ?'inputText1':'inputText0']"
              placeholder="请输入身份地址访问主页"
              ref="inputText"
              @input="inputModify"
              maxlength="20"
            />
          </div>
          <div class="btn h_v_center" @click="serbtn()">前往</div>
        </div>
        <div class="currRole">输入身份地址即可跳转</div>
      </div>
      
    </div>
    <div class="roleList h between">
      <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide between" v-for="(item,index) in list" :key="index">
              <div class="li relativeoh" @click="detailsClick(value.link_num)" v-for="(value,idx) in item" :key="idx">
                <img class="libg" v-show="!isMobile" :src="value.bg_url" alt="" />
                <div class="liCon v v_center">
                  <img class="avatar radius50" :src="value.avatar" alt="" />
                  <div class="names">{{ value.name }} </div>
                  <div class="links">Role.cn/{{ value.link_num }}</div>
                  <div class="tagList h wrap" v-if="!isMobile">
                    <div class="tags" v-for="(values,idx) in value.tags_list" :key="idx">{{ values }}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>

    </div>
    
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      inputText: "",
      client:"",
      href:"",
      hrefText:"",
      list:[
        [{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/5.png",
          'avatar': "https://img.imlinkapp.com/Fg6s6i0L1xDbWF-5ahPHKGAQqiae-small",
          'name': "如尔产品经理",
          'link_num': "0001",
          'tags_list': ["男", '33岁', "175cm", '等TA出现', "养猫", "INTJ", '理想主义者','互联网行业','大学本科']
        },
        {
          'bg_url': "https://img.imlinkapp.com/Fm4tn44PIwGFt-l1MYkIRUjEsdfI-source",
          'avatar': "https://img.imlinkapp.com/Fot69sOSBGNRGkHj_lJBzr-k0rgW-small",
          'name': "uu030",
          'link_num': "uu030",
          'tags_list': ["女", '165cm', "45kg",'武汉市','属蛇',"未婚", '摄影','法语','金牛座','电影','穿搭']
        },
        {
          'bg_url': "https://img.imlinkapp.com/FsNTzXs_HkKU6unnjVudyPxXh42I-source",
          'avatar': "https://img.imlinkapp.com/Fl7J7o1p5Yma_vpz9Ift8ZUasvPu-small",
          'name': "白菜脑婆",
          'link_num': "baibai",
          'tags_list': ["女", '大学专科',"165cm", "信阳市",'文化传播业',  "信阳市"]
        }],
        [{
          'bg_url': "https://img.imlinkapp.com/FiUcXxCX1ThK_mUDCjc5IdLSzthq-source",
          'avatar': "https://img.imlinkapp.com/FrsTin7l0ohek_dm6rxizbe7ax2A-small",
          'name': "qqxm",
          'link_num': "qqxmd",
          'tags_list': ["学生", '大学在读', "172cm", '四川省', "成都市", "女", '大学本科']
        },
        {
          'bg_url': "https://img.imlinkapp.com/Fk3V8w_Hrb7gdJkA2HwbZGkNJGcE-source",
          'avatar': "https://img.imlinkapp.com/Fk3V8w_Hrb7gdJkA2HwbZGkNJGcE-small",
          'name': "Qinyn",
          'link_num': "8j3xbe",
          'tags_list': ["女",'160cm',"软件工程师", '互联网行业', '湖北省',"武汉市", '大学本科']
        },
        {
          'bg_url': "https://img.imlinkapp.com/Fq-OJEYVPFBbydKuRa-F8xUOJAfG-source",
          'avatar': "https://img.imlinkapp.com/FkWo1gVrZnNIulagYNHpiOlz6Qjm-small",
          'name': "佳心",
          'link_num': "jiaxin",
          'tags_list': ["学生", "武汉市",'大学本科', "170cm",'十堰市',  "颜值876.0分"]
        }],
        
        [{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/1.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/u6cG6AuZto133l5MHX9hIZNtlmMiazoEcwnicYbRIkWbMPFInoEbMicPfys45uQwCw4BBU5YKYqprJBeg9zcTzvkcLSxfuu3ctVKKN4RgUzVNc/132",
          'name': "itsfire-",
          'link_num': "3ptj7r",
          'tags_list': ["女", '164cm', "44kg", '学生', "未婚", "大学本科", '瑜伽','音乐','美妆']
        },
        {
          'bg_url': "https://img.imlinkapp.com/FiPEZnW5gFW8bEhl8a9OztDPpJDF-source",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/VEnnXNL7jFWNGx0m47pzeucMntcVCUXDaXAZqSVzcCKXMJYXibE2KXH1gSwEiaARBxkhiaurJwTfMolWk5IcPQbobkDRhjZyfV6odykxgiau2k8/132",
          'name': "慢慢 ",
          'link_num': "ymm531",
          'tags_list': ["女", '属羊', "双子座", '穿搭', "健身", "王者荣耀", '孝感市','佛系脱单']
        },{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/3.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/u8RaO7icBs1hEz6mP9icZB0ibSmmicx2u3Ar5IibqLFaXFQz3nm9nxZU7c5oSRsY6o6LaOaic1s50610ficH8P82ebDGQ/132",
          'name': "白菜 ",
          'link_num': "baicaizhu",
          'tags_list': ["女", '京剧', "军事", '摔跤', "大学本科", '属猴','双鱼座',"恋爱中", ]
        }],
        [{
          'bg_url': "https://img.imlinkapp.com/FtkIzwHJkqTut4LmBH-cNSBEZAEc-source",
          'avatar': "https://img.imlinkapp.com/Fhp6zSIgherTrIvH6yTFwjp_VEGB-small",
          'name': "李砚卿 ",
          'link_num': "liyanqing",
          'tags_list': ["大学专科", '文化传播业', "170cm", '颜值883.0分', "恩施土家族苗族自治州",]
        },
        {
          'bg_url': "https://img.imlinkapp.com/Fm358aFJrbKAtxVDYQGG43neGwy2-source",
          'avatar': "https://img.imlinkapp.com/FolcyJkOX1pOEpKzF5g1Fwt3qNyN-small",
          'name': "李一林",
          'link_num': "cckkfg",
          'tags_list': ["美食", '白羊座', "运营", '男', ]
        },{
          'bg_url': "https://img.imlinkapp.com/FgDY1kAInwQKB1_wOfgHmxWorrzW-source",
          'avatar': "https://img.imlinkapp.com/FhPsXfi0z_Qk8bA21Bj0KcPKYfbZ-small",
          'name': "热木 ",
          'link_num': "ekram",
          'tags_list': ["咨询行业", '大学在读', "181cm", '硕士研究生', "新疆维吾尔自治区", "狮子座", '汽车','穿搭','未婚']
        }],
        [{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/1.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/4MSg8nNqhljdPmjppSQAicgGU3LMBpaicewgRofsyf1xx1Eia0BlzibZKMQiccB5197564BcWgeAIYvtAcB08WWKkbg/132",
          'name': "唧唧 ",
          'link_num': "jiji",
          'tags_list': ["女", '属龙', "处女座", '电影', "旅游", "和平精英", '王者荣耀','佛系脱单']
        },
        {
          'bg_url': "https://img.imlinkapp.com/role_bg/common/2.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqsawonjZ3e5EG9vEdeXEBnVFzcYUxGYyo4szr5BEUpxEW9AXCFzd0qMUtA3EcbFGEy3eq0TfX8MQ/132",
          'name': "汤姆布梨菠 ",
          'link_num': "lool1001",
          'tags_list': ["女", '168cm', "55kg", '天秤座','未婚', "电影", "旅游", '美食','美妆']
        },{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/5.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEIAk4d3eialeHxTFobSkyISKeIX7EPdXtojm0t7z1KDaSUfB5rUvGFPXOTKAHFib4nYHLF6N1c7n10FP4CH45WJoUrZ5NNDanrkxVE9mEuA6icjg/132",
          'name': "ㅤ ",
          'link_num': "bse0pl",
          'tags_list': ["女" ,'160cm','45kg','属兔', "天秤座", '电影', "旅游", "美食", '手绘']
        }],
        [{
          'bg_url': "https://img.imlinkapp.com/FvM3E6kWGgB2BK3OqJQW9HezggXH-source",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eomtGXciaK6d4Spnuyadgjrvyucw0Fhkh7yVxliaLiaP250vJjP4rTqcXRPjpD4QKeLAIOtDWib15CkTQ/132",
          'name': "球球呀🧸 ",
          'link_num': "tuyuan",
          'tags_list': ["女", '武汉市', "属蛇", '天秤座', "宠物", "旅游", '美食','王者荣耀','佛系脱单']
        },
        {
          'bg_url': "https://img.imlinkapp.com/role_bg/common/2.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/aGeg2UKDXxE1eEmP50vaKBe2449pLO1KXTDGnqfUXufCvoQJ9VonhIGbP9y2aibNF6T7H3ungPnIZ0ygHJgfR6V3hZdib0POYs5Mp16xUWp28/132",
          'name': "Cc ",
          'link_num': "ccccc",
          'tags_list': ["男", '182cm', "60kg", '未婚', "属马", "汕尾市", '天秤座','电影','钓鱼','旅游']
        },{
          'bg_url': "https://img.imlinkapp.com/role_bg/common/4.png",
          'avatar': "https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEI97yrZJrs3sZTHNibOD5mg1Iicu9SO6ICMib0Su7XfqT6VY5jsWk3ricHBoIZjGJz3Vn9CvzXHsicrNZXqb8MGeMO1x2lxiaj4svMH6Hp8Kc4NUHww/132",
          'name': "琛 ",
          'link_num': "wuzichen",
          'tags_list': ["男", '属龙', "巨蟹座", '唱歌', "音乐", "王者荣耀", '大学本科','期待恋爱','黄冈市']
        }],
      ],
    };
  },
  created(){
    this.mySwiperfunc();
  }, 
  methods: {
    //Swiper组件
    mySwiperfunc() {
      this.mySwiper = new Swiper(".swiper-container", {
        observer: true, //开启动态检查器，监测swiper和slide
        loop: false,
        direction: "horizontal",
        // 鼠标可滑动
        mousewheelControl: false,
        // 如果需要分页器
        pagination : {
          el: '.swiper-pagination',
          type: 'bullets',
        }
      });
    },
    detailsClick(links){
      window.open('https://role.cn/'+links)
    },
    inputModify(){
      let inputText = document.querySelector('#inputText').clientWidth
        if(this.isMobile){
          this.$refs.inputText.style.width = `${this.inputText.length * 8}px`
          if(inputText < 50 && this.inputText == ''){
            this.$refs.inputText.style.width = `160px`
          }
        }else{
          /* this.$refs.inputText.style.width = `${this.inputText.length * 50}px`
          console.log(inputText,this.inputText)
          if(inputText < 50 && this.inputText == ''){
            this.$refs.inputText.style.width = `400px`
          } */
        }
    }, 
    serbtn() {
      if(this.inputText.trim()){
        window.open('https://role.cn/'+this.inputText.trim())
      }
      
      /* this.inputText.toLowerCase()
      switch(location.href.slice(0,5)){
        case 'http:':this.href = 'http';break;
        case 'https':this.href = 'https';break;
      }
      
      if(this.inputText != ""){
        if(this.isMobile){
          if(location.href.slice(0,16) == `${this.href}://role.cn/`){

            if(this.inputText.slice(0,16) == `${this.href}://role.cn/`){
              document.location.href = `${this.inputText}`

            } else if(this.inputText.slice(0,8) == 'role.cn/'){
              document.location.href = `${this.href}://${this.inputText}`

            } else if(this.inputText.slice(0,20) == `${this.href}://www.role.cn/`){
              document.location.href = `${this.href}://role.cn/${this.inputText.slice(20 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,12) == 'www.role.cn/'){
              document.location.href = `${this.href}://role.cn/${this.inputText.slice(12 ,this.inputText.length)}`
              
            } else {
              document.location.href = `${this.href}://role.cn/${this.inputText}`
            } 
          }


          if(location.href.slice(0,20) == `${this.href}://www.role.cn/`){

            if(this.inputText.slice(0,16) == `${this.href}://role.cn/`){
              document.location.href = `${this.href}://www.role.cn/${this.inputText.slice(16 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,8) == 'role.cn/'){
              document.location.href = `${this.href}://www.role.cn/${this.inputText.slice(8 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,20) == `${this.href}://www.role.cn/`){
              document.location.href = `${this.inputText}`

            } else if(this.inputText.slice(0,12) == 'www.role.cn/'){
              document.location.href = `${this.href}://${this.inputText}`
              
            } else {
              document.location.href = `${this.href}://www.role.cn/${this.inputText}`
            } 
          }


         } else {
          let tempwindow = window.open('_blank');
          if(location.href.slice(0,16) == `${this.href}://role.cn/`){

            if(this.inputText.slice(0,16) == `${this.href}://role.cn/`){
              tempwindow.location = `${this.inputText}`

            } else if(this.inputText.slice(0,8) == 'role.cn/'){
              tempwindow.location = `${this.href}://${this.inputText}`

            } else if(this.inputText.slice(0,20) == `${this.href}://www.role.cn/`){
              tempwindow.location = `${this.href}://role.cn/${this.inputText.slice(20 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,12) == 'www.role.cn/'){
              tempwindow.location = `${this.href}://role.cn/${this.inputText.slice(12 ,this.inputText.length)}`
              
            } else {
              tempwindow.location = `${this.href}://role.cn/${this.inputText}`
            } 
          }


          if(location.href.slice(0,20) == `${this.href}://www.role.cn/`){

            if(this.inputText.slice(0,16) == `${this.href}://role.cn/`){
              tempwindow.location = `${this.href}://www.role.cn/${this.inputText.slice(16 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,8) == 'role.cn/'){
              tempwindow.location = `${this.href}://www.role.cn/${this.inputText.slice(8 ,this.inputText.length)}`

            } else if(this.inputText.slice(0,20) == `${this.href}://www.role.cn/`){
              tempwindow.location = `${this.inputText}`

            } else if(this.inputText.slice(0,12) == 'www.role.cn/'){
              tempwindow.location = `${this.href}://${this.inputText}`
              
            } else {
              tempwindow.location = `${this.href}://www.role.cn/${this.inputText}`
            } 
          }
        }
      } */
    },
    
  },
  mounted(){
    
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>
.guide2 {
  @include scss_wh(100%, auto);
  .titlesCon{
    .titles1{
      @include scss_fch(0.28rem, #FFE600, 0.47rem);
      margin-top: 1.08rem;
      margin-bottom: 0.2rem;
      letter-spacing: 1px;
    }
    .titles2{
      @include scss_fcwh(0.48rem, #121127, 0.72rem);
      margin-bottom: 0.32rem;
    }
    .titles3{
      @include scss_fch(0.26rem,  rgba(18, 17, 39, 0.56), 0.57rem);
    }
  }
  .search {
    @include scss_whbb(100%,7.78rem, #FFE600);
    margin: 0.62rem auto 0;
    padding: 0.88rem 5.35% 0; 
    @include scss_flex_h(column);
    align-items: center;
    .searchL{
      text-align: left;
      .titles{
        @include scss_fcwh(0.64rem, #000, 0.9rem);
        margin-bottom: 0.24rem;
      }
      .tips{
        @include scss_fch(0.26rem, #000, 0.57rem);
        margin-bottom: 0.64rem;
      }
    }
    .searchR{
      @include scss_wh(100%,auto);
      .inputCon{
        @include scss_wh(100%,auto);
        @include scss_flex_h(column);
        align-items: center;
        .inputLeft {
          @include scss_whbb(100%,1.04rem, #fff,0.16rem);
          padding: 0 0.32rem;
          @include scss_fcwh(0.28rem, #121127, 0.5rem);
          input {
            width: 100%;
          }
        }
        .btn {
          @include scss_whbb(100%,1.04rem, #000,0.16rem);
          @include scss_fch(0.32rem, #ffff);
          cursor: pointer;
          margin-top: 0.32rem;
          margin-bottom: 0.24rem;
        }
      }
      .currRole{
        @include scss_fch(0.24rem, #000, 0.42rem);
        margin-bottom: 0.3rem;
      }
    }
  }
  .roleList{
    @include scss_whbb(100%,4.14rem, #FFE600);
    padding: 0 4.7% 0 4.2%;
    .li{
      @include scss_whbb(31.9%, 2.82rem,#fff,0.24rem);
      .libg{
        @include scss_wh(100%, 1.97rem);
        @include scss_alt();
        z-index: 0;
        object-fit: cover;
      }
      .liCon{
        @include scss_wh(100%, 100%);
      }
      .avatar{
        @include scss_wh(0.96rem, 0.96rem);
        margin-top: 0.42rem;
        margin-bottom: 0.2rem;
        object-fit: cover;
      }
      .names{
        @include scss_fcwh(0.24rem, #121127,0.5rem);
      }
      .links{
        @include scss_fch(0.24rem, rgba(18, 17, 39, 0.56),0.43rem);
      }
    }
  }
}
.guide {
  @include scss_wh(100%, auto);
  .titlesCon{
    .titles1{
      @include scss_fch(14px, #FFE600, 24px);
      margin-top: 96px;
      margin-bottom: 12px;
      letter-spacing: 1px;
    }
    .titles2{
      @include scss_fcwh(40px, #121127, 56px);
      margin-bottom: 12px;
    }
    .titles3{
      @include scss_fch(14px,  rgba(18, 17, 39, 0.56), 32px);
    }
  }
  .search {
    @include scss_whbb(78.3%,227px, #FFE600,21px);
    max-width: 1128px;
    margin: 86px auto 24px;
    @include scss_flex_h(row);
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    .searchL{
      width: 496px;
      text-align: left;
      .titles{
        @include scss_fcwh(32px, #000, 45px);
        margin-bottom: 12px;
      }
      .tips{
        @include scss_fch(14px, #000, 29px);
        span{
          display: block;
        }
      }
    }
    .searchR{
      @include scss_wh(504px,auto);
      .inputCon{
        @include scss_wh(100%,52px);
        margin-bottom: 12px;
        @include scss_flex_h(row);
        .inputLeft {
          @include scss_whbb(386px,52px, #fff,8px);
          padding: 13px 16px;
          @include scss_fch(14px, #121127, 26px);
          input {
            width: calc(100% - 77px);
          }
          input::placeholder{
          }
        }
        .btn {
          @include scss_whbb(96px,52px, #000,8px);
          @include scss_fch(16px, #ffff);
          cursor: pointer;
          margin-left: 16px;
        }
      }
      .currRole{
        @include scss_fch(14px, #000, 22px);
      }
    }
  }
  ::v-deep .swiper-container{
    @include scss_wh(100%, 435px);
    margin: 0;
    .swiper-slide{
      width: 100% !important;
      padding: 0 24px;
    }
    img{
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .roleList{
    width: 78.3%;
    max-width: 1128px;
    margin: 0 auto;
    .li{
      @include scss_whbb(31.9%, 385px,#fff,12px);
      box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
      cursor: pointer;
      .libg{
        @include scss_wh(100%, 148px);
        @include scss_alt();
        z-index: 0;
        object-fit: cover;
      }
      .liCon{
        @include scss_alt(0,96px);
        width: 100%;
      }
      .avatar{
        @include scss_wh(96px, 96px);
        margin-bottom: 16px;
        object-fit: cover;
        z-index: 10;
      }
      .names{
        @include scss_fcwh(14px, #121127,26px);
      }
      .links{
        @include scss_fch(12px, rgba(18, 17, 39, 0.56),22px);
        margin-bottom: 29px;
      }
      .tagList{
        margin: 0 20px;
        height: 64px;
        overflow: hidden;
        .tags{
          @include scss_whbb(auto, 24px,none,12px);
          padding: 0 16px;
          @include scss_fcwh(12px, rgba(18, 17, 39, 0.56),24px);
          border: solid 1px rgba(18, 17, 39, 0.56);
          margin-right: 13px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

</style>
