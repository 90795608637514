<template>
  <!-- 关于页 -->
  <div id="About" :class="[isMobile?'about2':'about']">
    <div class="aboutCon">
      <div class="title1">OUR VISION MISSION VALUE</div>
      <div class="title2">我们的愿景、使命、价值观</div>
      <div class="title3" v-show="!isMobile">难而正确的事总有人会成功，希望是我们</div>

      <div class="ul">
        <div class="li li1">
          <div class="titles">愿景</div>
          <div class="dl">
            <div class="dd">
              <div class="txt1">数据平权</div>
              <div class="txt2">每个人都能平等使用所有人的数据</div>
            </div>
            <div class="dd">
              <div class="txt1">自由连接</div>
              <div class="txt2">每个人都能与世界上所有人建立连接</div>
            </div>
          </div>
        </div>
        <div class="li li2">
          <div class="titles">使命</div>
          <div class="dl">
            <div class="dd">
              <div class="txt1">降低数据成本</div>
              <div class="txt2">用户主导的数据一次采集无数次复用</div>
            </div>
            <div class="dd">
              <div class="txt1">提升连接效率</div>
              <div class="txt2">用户本人从无限维度信息中精准筛选</div>
            </div>
            <div class="dd">
              <div class="txt1">改造商业场景</div>
              <div class="txt2">Web3平台靠服务<br>而非信息差赚钱</div> 
            </div>
          </div>
        </div>
        <div class="li li2">
          <div class="titles">价值观</div>
          <div class="dl">
            <div class="dd">
              <div class="txt1">和用户保持利益一致</div>
              <div class="txt2">通过为用户创造价值分享有限的收益</div>
            </div>
            <div class="dd">
              <div class="txt1">实事求是，尊重需求</div>
              <div class="txt2">淡化数据“所有权”，规范数据“使用权”</div>
            </div>
            <div class="dd">
              <div class="txt1">追求更好而非完美</div>
              <div class="txt2">在进步中做错事总好过裹足不前</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>
.about2{
  @include scss_wh(100%, auto);
  .aboutCon{
    @include scss_wh(89.3%, auto);
    margin: 0 auto 1.28rem;
    .title1{
      @include scss_fcwh(0.28rem, #FFE600, 0.47rem);
      margin-bottom: 0.24rem;
    }
    .title2{
      @include scss_fcwh(0.48rem, #121127, 0.72rem);
      margin-bottom: 0.56rem;
    }
    .ul{
      @include scss_flex_h(row);
      flex-wrap: wrap;
      .li{
        .titles{
          @include scss_fcwh(0.48rem, #FFEB3B, 0.72rem);
          margin-bottom: 0.24rem;
        }
        .dl{
          width: 100%;
          
          .dd{
            @include scss_fch(0.28rem, rgba(18, 17, 39, 0.56), 0.5rem);
            margin-bottom: 0.32rem;
            .txt1{
              font-weight: bold;
              color: #000;
            }
            .txt2{
              width: 80%;
            }
          }
        }
      }
      .li1{
        width: 100%;
        .dl{
          @include scss_flex_h(row);
          .dd{
            width: 50%;
          }
        }
      }
      .li2{
        width: 50%;
      }
    }
  }
}
.about{
  @include scss_wh(100%, auto);
  .aboutCon{
    @include scss_wh(78%, 100%);
    max-width: 1128px;
    margin: 0 auto 132px;
    padding-left: 82px;
    .title1{
      @include scss_fcwh(14px, #FFE600, 24px);
      margin-bottom: 12px;
    }
    .title2{
      @include scss_fcwh(40px, #121127, 56px);
      margin-bottom: 16px;
    }
    .title3{
      @include scss_fch(14px, rgba(18, 17, 39, 0.72), 32px);
      margin-bottom: 45px;
    }
    .ul{
      .li{
        padding: 32px 0;
        .titles{
          @include scss_fcwh(24px, #FFEB3B, 34px);
          margin-bottom: 12px;
        }
        .dl{
          width: 100%;
          @include scss_flex_h(row);
          .dd{
            width: 33.3%;
            @include scss_fch(14px, rgba(18, 17, 39, 0.56), 25px);
            .txt1{
              color:#000;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>