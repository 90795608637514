<template>
  <!-- 首页 -->
  <div id="Home" :class="[isMobile?'Home2':'Home']">
    <div class="homeMain relative">
      <div class="content relative">
        <div class="titles">
          <span>Web3是</span>
          <span>真正属于用户的互联网</span>
        </div>
        <div class="subtitle">
          <span>互联网的第一性是连接，数据是建立连接最重要的生产要素；</span>
          <span>当用户从平台手中夺回生产要素所有权，试看互联网天地翻覆。</span>
        </div>
        <div class="link relative" v-if="!isMobile">
          <div class="imgFrame">
            <img @click="btnIOS" class="btnIOS" src="../../website/assets/img/home/iosbtn.png" alt="" />
            <div class="IOS">
              <img class="QRIOS" src="../../website/assets/img/ios.png" alt="" srcset="" />
            </div>
          </div>
          <div class="imgFrame">
            <img @click="btnAni" class="btnAni" src="../../website/assets/img/home/anibtn.png" alt="" />
            <div class="Ani">
              <img class="QRAni" src="../../website/assets/img/ani.png" alt="" srcset="" />
            </div>
          </div>
        </div>
        <div class="btnCon h_v_center" v-if="isMobile" @click="dow">立即下载</div>
      </div>
      <div class="homeMp4 relativeoh h_v_center">
        <img class="videoEnd" src="https://sinianxingkong.oss-cn-beijing.aliyuncs.com/demo/poster.jpg" alt="" v-show="isMobile" />
        <!-- pc -->
        <div class="replay h_v_center" v-show="isVideoEnd" @click="mobileVideoPlay()">
          <img class="playbtn" src="../../website/assets/img/home/play2.png" alt="" />
        </div>
        <video-player class="homeMp4Video" ref="videoPlayer" :playsinline="true"
          style="object-fit: fill" :options="playerOptions" :x5-video-player-fullscreen="true"
          @ended="onPlayerEnded($event)" @ready="playerReadied" v-if="!isMobile">
        </video-player>
      </div>
    </div>
    
    <div class="videoClassBtn" v-if="is_videoClass">
      <div class="closeImg" @click="closeVideo()">
        <img src="https://sinianxingkong.oss-cn-beijing.aliyuncs.com/img/close.svg" alt="" />
      </div>
      <video controls autoplay src="https://img.imlinkapp.com/8%E6%9C%8830%E6%97%A5-%E5%8F%A3%E6%92%AD%E9%87%8D%E5%BD%95-%E5%B0%8F.mp4"></video>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      is_videoClass: false,//手机显示视频
      isVideoEnd: false,//播放完成
      isVideoReady: false,//视频加载完成
      playerOptions: {
        isSB: "",
        controls: true, //控制条
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "https://img.imlinkapp.com/8%E6%9C%8830%E6%97%A5-%E5%8F%A3%E6%92%AD%E9%87%8D%E5%BD%95-%E5%B0%8F.mp4",//https://img.imlinkapp.com/site.mp4
          },
        ],
        poster: "https://sinianxingkong.oss-cn-beijing.aliyuncs.com/demo/poster.jpg",
        // width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  mounted() {
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        video.play();
        video.pause();
      },
      false
    );
    console.log(this.isMobile)
    
  },
  methods: {
    closeVideo() {
      this.is_videoClass = false
    },
    mobileVideoPlay() {
      this.is_videoClass = true
    },
    // 播放回调 视频系列 start
    videoPlay() {
      this.$refs.videoPlayer.player.play() // 播放
      this.isVideoEnd = false
    },
    // 视频播完回调
    onPlayerEnded($event) {
      this.isVideoEnd = true
      console.log('视频播放完成----', this.isVideoEnd)
    },
    playerReadied() {
      console.log('加载完成')
      // this.isVideoReady = true
    },
    play(elm) {
      console.log("play", elm);
    },
    pause(e) {
      console.log("pause");
    },
    playend(e) {
      // alert("播放结束");
    },
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
  },
  props: ["isMobile"],
  watch: {
    /* isAudio(nv) {
      this.playerOptions.muted = !nv
      this.isVideoEnd = false
    }, */
    isMobile(nv){
      if (this.isMobile) {
        this.isVideoEnd = true
      }else{
        this.isVideoEnd = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.videoClassBtn {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);

  video {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 21px;
  }

  .closeImg {
    background: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 999999;
    padding: 10px;
    right: 3%;
    top: 12%;
    line-height: initial;
  }
}
::v-deep .vjs-progress-control .vjs-control .video-js .vjs-progress-control {
  opacity: 0 !important;
  display: none !important;
}

::v-deep .vjs-tech {
  object-fit: cover !important;
  border-radius: 21px;
}
::v-deep .video-js{
  border-radius: 21px;
  .vjs-big-play-button{
    @include scss_whbb(80px, 80px,#FFE600,50%);
    border: 0;
    @include scss_alt(50%,50%);
    transform: translate(-50%,-50%);
    .vjs-icon-placeholder{
      position: relative;
      @include scss_wh(25px, 25px);
      @include scss_alt(50%,50%);
      transform: translate(-50%,-50%);
    }
    .vjs-icon-placeholder:before{
      font-size: 49px;
      transform: translate(-50%, -38%);
    }
  }
}

::v-deep .vjs-poster {
  background-size: cover !important;
}

.posterImg {
  width: auto !important;
}

.Home {
  @include scss_wh(100%, 800px);
  background: linear-gradient(225deg, rgba(255, 241, 241, 1) 0%, rgba(233, 248, 251, 1) 100%), rgba(204, 204, 204, 1);
  .homeMain{
    @include scss_wh(78.3%, 100%);
    margin: 0 auto;
    max-width: 1128px;
    @include scss_flex_h(row);
    justify-content: space-between;
    align-items: center;
  }
  .homeMp4 {
    width: 48.9%;
    height: 311px;
    object-fit: fill;
    background-repeat: repeat;
    border-radius: 16px;
    .replay{
      @include scss_whbb(100%, 100%,rgba(0,0,0,0.3));
      @include scss_alt;
      z-index: 99;
      .playbtn{
        @include scss_wh(80px, 80px);
      }
    }
    .homeMp4Video{
      @include scss_wh(100%, 100%);
    }
  }

  .content {
    @include scss_wh(43.3%,auto);
    text-align: left;
    .titles{
      margin-bottom: 12px;
      span{
        @include scss_fcwh(40px, #121127, 56px);
        display: block;
      }
    }
    .subtitle {
      @include scss_fch(14px, rgba(18, 17, 39, 0.56), 29px);
      margin-bottom: 24px;
      span{
        display: block;
      }
    }
    .link {
      display: flex;
      .imgFrame {
        width: 190px;
        height: 60px;
        margin-right: 32px;
        position: relative;

        .btnIOS,.btnAni {
          width: 100%;
          max-height: 100%;
          border-radius: 8px;
          box-shadow: inset 0px 0px 1px 1px #fff;
          cursor: pointer;
        }

        .IOS,.Ani {
          width: 164px;
          height: 176px;
          background-image: url(../../website/assets/img/QR.svg);
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: 0% 20%;
          position: absolute;
          left: 50%;
          top: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translate(-50%, 0);
          z-index: 99999;
        }
      }

      .QRIOS,
      .QRAni {
        width: 148px;
        height: 148px;
        margin-top: 20px;
        // display: none;
      }
    }
  }
}
.Home2 {
  @include scss_wh(100%, 12.34rem);
  background: linear-gradient(225deg, #FFF1F1 0%, #E9F8FB 100%);
  .homeMain{
    @include scss_wh(89.3%, 100%);
    margin: 0 auto;
    @include scss_flex_h(column-reverse);
  }
  .homeMp4 {
    @include scss_wh(100%, 3.78rem);
    @include scss_alt(0,1.5rem);
    object-fit: fill;
    background-repeat: repeat;
    border-radius: 0.32rem;
    .videoEnd{
      @include scss_wh(100%, 100%);
    }
    .replay{
      @include scss_whbb(100%, 100%,rgba(0,0,0,0.3));
      @include scss_alt;
      z-index: 99;
      .playbtn{
        @include scss_wh(0.96rem, 0.96rem);
      }
    }
  }

  .content {
    text-align: left;
    line-height: initial;
    @include scss_alt(0,6.16rem);
    .titles{
      margin-bottom: 0.24rem;
      span{
        @include scss_fcwh(0.64rem, #121127, 0.9rem);
        display: block;
      }
    }
    .subtitle {
      @include scss_fch(0.26rem, rgba(18, 17, 39, 0.56), 0.57rem);
      margin-bottom: 0.48rem;
    }
    .btnCon {
      @include scss_whbb(100%, 1.04rem,#FFE600,0.16rem);
      @include scss_fcwh(0.32rem, #000);
    }
  }
}
.IOS,
.Ani {
  display: none !important;
}

.btnIOS:hover+.IOS {
  display: flex !important;
}

.btnAni:hover+.Ani {
  display: flex !important;
}
</style>
