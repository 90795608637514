<template>
  <div id="app" ref="app">
    <Nav :isMobile="isMobile" :types="1" :isScrollTop="isScrollTop" />
    <div class="main">
      <Home :isMobile="isMobile" />
      <Guide :isMobile="isMobile" />
      <Database :isMobile="isMobile" :isSB="isSB" />
      <Identity :isMobile="isMobile" />
      <Search :isMobile="isMobile" />
      <About :isMobile="isMobile" />
      <Footer :isMobile="isMobile" />
      <a v-show="isScrollTop" :class="[isMobile?'icotop2':'icotop']" href="#app"><img src="../website/assets/img/top.png" alt="" /></a>
      <div class="kefu1" v-if="!isMobile">
        <div class="kfewm relative v h_v_center">
          <img class="ewmbg" src="../website/assets/img/ewmbg.png" alt="" />
          <img class="qrcode" src="../website/assets/img/qrcode.png" alt="" />
          <span>微信扫码对话</span>
        </div>
        <img class="ico" src="../website/assets/img/kefu.png" alt="" />
      </div>
      <a class="kefu2" href="https://work.weixin.qq.com/kfid/kfc2c6615e04dd637bd" v-else>
        <img src="../website/assets/img/kefu.png" alt="" />
      </a>
      
    </div>
  </div>
</template>

<script>
import Nav from "./nav/Nav.vue";
import Home from "./home/Home2.vue";
import Guide from "./guide/Guide.vue";
import Database from "./database/Database.vue";
import Identity from "./identity/Identity.vue";
import Search from "./search/Search.vue";
import About from "./about/About.vue";
import Footer from "./footer/Footer.vue";
export default {
  components: {
    Nav,
    Home,
    Guide,
    Database,
    Identity,
    Search,
    About,
    Footer,
  },
  name: "SwiperFrame",
  data() {
    return {
      isMobile: false,
      isSB:'',
      winWidth: 0,
      isScrollTop: false,
    };
  },
  mounted() {
    this.userAgent();
    this.winWidth = window.innerWidth
    /* let isFirst = sessionStorage.getItem("isFirst");
    if (this.isMobile && !isFirst) {
      sessionStorage.setItem("isFirst", 1);
      this.$router.replace('/nfcChip');
    } */

    // 监听页面滚动事件
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {

  },
  methods: {
    //判断系统
    userAgent() {
      function detectOS() {
        let sUserAgent = navigator.userAgent;
        let isWin =
          navigator.platform == "Win32" || navigator.platform == "Windows";
        let isMac =
          navigator.platform == "Mac68K" ||
          navigator.platform == "MacPPC" ||
          navigator.platform == "Macintosh" ||
          navigator.platform == "MacIntel";
        if (isMac) return "Mac";
        let isUnix = navigator.platform == "X11" && !isWin && !isMac;
        if (isUnix) return "Unix";
        let isLinux = String(navigator.platform).indexOf("Linux") > -1;
        if (isLinux) return "Linux";
        if (isWin) {
          let isWin2K =
            sUserAgent.indexOf("Windows NT 5.0") > -1 ||
            sUserAgent.indexOf("Windows 2000") > -1;
          if (isWin2K) return "Win2000";
          let isWinXP =
            sUserAgent.indexOf("Windows NT 5.1") > -1 ||
            sUserAgent.indexOf("Windows XP") > -1;
          if (isWinXP) return "WinXP";
          let isWin2003 =
            sUserAgent.indexOf("Windows NT 5.2") > -1 ||
            sUserAgent.indexOf("Windows 2003") > -1;
          if (isWin2003) return "Win2003";
          let isWinVista =
            sUserAgent.indexOf("Windows NT 6.0") > -1 ||
            sUserAgent.indexOf("Windows Vista") > -1;
          if (isWinVista) return "WinVista";
          let isWin7 =
            sUserAgent.indexOf("Windows NT 6.1") > -1 ||
            sUserAgent.indexOf("Windows 7") > -1;
          if (isWin7) return "Win7";
          let isWin8 =
            sUserAgent.indexOf("Windows NT 8.0") > -1 ||
            sUserAgent.indexOf("Windows 8") > -1;
          if (isWin8) return "Win8";
          let isWin10 =
            sUserAgent.indexOf("Windows NT 10.0") > -1 ||
            sUserAgent.indexOf("Windows 10") > -1;
          if (isWin10) return "Win10";
        }
        return "other";
      }
      //判断是手机还是电脑，什么浏览器，手机操作系统
      let browser = (function () {
        let ua = navigator.userAgent;
        let isWindowsPhone = /(?:Windows Phone)/.test(ua);
        let isSymbian = /(?:SymbianOS)/.test(ua);
        let isAndroid = /(?:Android)/.test(ua);

        let isFireFox = /(?:Firefox)/.test(ua);

        let isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua));
        let isPhone = /(?:iPhone)/.test(ua) && !isTablet;

        let isChrome = /(?:Chrome|CriOS)/.test(ua);

        let isMobile = isWindowsPhone || isSymbian || isAndroid || isPhone;
        let isPc = !isMobile;
        return {
          isMobile: isMobile,
          isPc: isPc,
          isPhone: isPhone, //苹果
          isWindowsPhone: isWindowsPhone, //微软手机
          isSymbian: isSymbian, //班赛
          isAndroid: isAndroid, //安卓
          isFireFox: isFireFox,
          isChrome: isChrome,
          isTablet: isTablet, //平板
        };
      })();
      let visit_type; //手机or电脑or平板
      let userBrowser; //浏览器
      let userOs; //操作系统

      if (browser.isPc) {
        //电脑
        visit_type = "pc";
        //如果是电脑则判断其操作系统
        userOs = detectOS();
        if (browser.isFireFox) {
          userBrowser = "火狐";
        } else if (browser.isChrome) {
          userBrowser = "谷歌";
        } else {
          userBrowser = "ie";
        }
      } else if (browser.isMobile) {
        //手机
        visit_type = "手机";
        userBrowser = "手机应用";
        if (browser.isPhone) {
          userOs = "苹果";
        } else if (browser.isWindowsPhone) {
          userOs = "微软";
        } else if (browser.isSymbian) {
          userOs = "塞班";
        } else if (browser.isAndroid) {
          userOs = "安卓";
        } else {
          userOs = "其他";
        }
      } else if (browser.isTablet) {
        visit_type = "平板";
        userOs = "其他";
        userBrowser = "其他";
      } else {
        visit_type = "其他";
        userOs = "其他";
        userBrowser = "其他";
      }
      this.isMobile = browser.isMobile;
      if (userOs == "苹果") {
        this.isSB = "IOS";
      } else {
        this.isSB = "ANI";
      }
    },
    handleScroll() {
      this.$nextTick(() => {
        if (document.documentElement.scrollTop > 100) {
          this.isScrollTop = true;
        } else {
          this.isScrollTop = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icotop2{
  width: 0.9rem;
  height: 0.9rem;
  bottom: 1.59rem;
  right: 0.49rem;
  position: fixed;
  z-index: 9;
  img{
    width: 100%;
    height: 100%;
  }
}
.icotop{
  width: 48px;
  height: 48px;
  bottom: 112px;
  right: 45px;
  position: fixed;
  z-index: 9;
  img{
    width: 100%;
    height: 100%;
  }
}
.kefu1{
  width: 48px;
  height: 48px;
  bottom: 45px;
  right: 45px;
  position: fixed;
  z-index: 9;
  .kfewm{
    display: none;
    position: absolute;
    top: -160px;
    left: -70px;
    width: 140px;
    height: 152px;
    text-align: center;
    padding-right: 5px;
    span{
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      color: #000;
      margin-bottom: 12px;
    }
    .ewmbg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .qrcode{
      width: 90px;
      height: 90px;
    }
  }
  .ico{
    width: 100%;
    height: 100%;
  }
}
.kefu1:hover{
  .kfewm{
    display: flex;
  }
}
.kefu2{
  width: 0.9rem;
  height: 0.9rem;
  bottom: 0.49rem;
  right: 0.49rem;
  position: fixed;
  z-index: 9;
  img{
    width: 100%;
    height: 100%;
  }
}
//导航
::v-deep .nav {
  i {
    @include scss_alb(85px, -5px);
  }
  #home {
    font-weight: 600;
    color: #2A0F23;
  }
}
::v-deep .subNavContent{
  #home {
    font-weight: 600;
    color: #2A0F23;
  }
}
//swiper
#app {
  width: 100%;
}
</style>
