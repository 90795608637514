<template>
  <div id="app">
    <Nav :isMobile="isMobile" :types="1" :isScrollTop="isScrollTop" />
    <!-- 数据-->
    <div class="relativeoh" :class="[isMobile ? 'searchList' : 'searchList2']">
      <div class="searchListCon">
        <div class="searchMain" v-if="!isMobile">
          <div class="searchTop v">
            <span class="h_center title1">从数百个维度</span>
            <span class="h_center title1">精准找到你的意中人</span>
            <span class="h_center title2">基于Web3理念，让每个人的数据合规流通，用户间直接联系</span>
          </div>
          <div class="searchSelect">
            <div class="roles relative h h_v_center">
              <div class="roleCon h h_v_center">
                <span :class="{'currRole':currRole == 'common'}" @click.stop="seletCurrRole(1)">元身份</span>
                <span :class="{'currRole':currRole == 'love'}" @click.stop="seletCurrRole(2)">脱单身份</span>
              </div>
              <span class="clear" @click="clearClick()">清空筛选</span>
            </div>
            <div class="ul">
              <div class="li h" :class="'li'+(index+1)" v-for="(item,index) in selectList" :key="item.names">
                <span class="types">{{ item.names }}</span>
                <div class="dl h v_center wrap" :class="{'isDisabled':isDisabled && index == 0}">
                  <div class="dd h_v_center" :class="{'active':currSelectIdx[index] == idx}" v-for="(items,idx) in item.values" :key="idx" @click="listClick(items,index,idx)">{{ items }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="tips h_center" :class="{'shakeX':isAnimate}" @click="dow()">高级筛选请下载App使用！</div> -->
          </div>
        </div>
        <div class="header" v-if="isMobile">
          <div class="conb relative h v_center between">
            <ul class="h">
              <li class="h v_center relativeoh" @click="selectRole()">
                <span>{{currRole == 'common'?'元身份 ':'脱单身份'}}</span>
                <img class="icon0" src="../../website/assets/img/select3.png" />
              </li>
              <li class="h v_center relativeoh" :class="{'selected':currSelect == 0,'active':selectArr[0]}" @click="selectClick(0)">
                <span>{{selectArr[0]?selectArr[0]:'性别'}}</span>
                <img src="../../website/assets/img/select2.svg" />
              </li>
              <li class="h v_center relativeoh" :class="{'selected':currSelect == 1,'active':selectArr[1]}" @click="selectClick(1)">
                <span>{{selectArr[1]?selectArr[1]:'年龄'}}</span>
                <img src="../../website/assets/img/select2.svg" />
              </li>
              <li class="h v_center relativeoh" :class="{'selected':currSelect == 2,'active':selectArr[2]}" @click="selectClick(2)">
                <span>{{selectArr[2]?selectArr[2]:'学历'}}</span>
                <img src="../../website/assets/img/select2.svg" />
              </li>
              <li class="h v_center relativeoh" :class="{'selected':currSelect == 3,'active':selectArr[3]}" @click="selectClick(3)">
                <span>{{selectArr[3]?selectArr[3]:'星座'}}</span>
                <img src="../../website/assets/img/select2.svg" />
              </li>
            </ul>
            <div class="conbr h h_v_center" @click="more()">
              <span class="roleName">更多</span>
              <img src="../../website/assets/img/more3.svg" />
            </div>
          </div>
          <!-- <div class="roleArr whplt" v-if="isRole">
            <div class="bgBox" @click="isRole = false"></div>
            <div class="roleArrCon v">
              <em class="pl50"></em>
              <span class="h_v_center bor" @click="selectRole('common')">元身份<img src="../../website/assets/img/selected2.svg" v-show="currRole == 'common'" /></span>
              <span class="h_v_center" @click="selectRole('love')">脱单身份<img src="../../website/assets/img/selected2.svg" v-show="currRole == 'love'" /></span>
            </div>
          </div> -->
          <div class="selectListMain" v-show="isSelect">
            <div class="bgBox" @click="close()"></div>
            <div class="selectList relative">
              <ul class="h wrap" v-if="currSelect != 1">
                <li class="h v_center relativeoh" :class="{'active':optionsArrIdx == index}" v-for="(item,index) in optionsArr" :key="item" @click="optionsClick(item,index)">
                  <span>{{ item }}</span>
                  <img src="../../website/assets/img/selected2.svg" v-show="optionsArrIdx == index" />
                </li>
              </ul>
              <div class="ageArr relative" v-if="currSelect == 1">
                <van-slider v-model="ageArr" :min="16" :max="45" range @change="onChange" active-color="#FFE600">
                  <template #left-button>
                    <div class="custom-button">{{ ageArr[0] }}</div>
                  </template>
                  <template #right-button>
                    <div class="custom-button">{{ ageArr[1] }}</div>
                  </template>
                </van-slider>
              </div>
              <div class="btnCon h between">
                <div class="btn h_v_center btn1" @click="reset()">重置</div>
                <div class="btn h_v_center btn2" @click="sure()">确定</div>
              </div>
            </div>
          </div>
        </div>
        <div class="resultCon">
          <div class="titles" v-if="!isMobile">找到{{resultNum>99?'99+':resultNum}}个符合要求的结果</div>
          <div class="h wrap commonUl" v-if="resultList && resultList.length > 0 && currRole == 'common'">
            <div class="li relative" v-for="(item,index) in resultList" :key="index" @click="detailsClick(item.user_meta.link_num)">
              <div class="userCon h v_center">
                <img class="avatar radius50" :src="item.avatar" />
                <div class="conr v">
                  <div class="names h v_center">
                    {{ item.name }}
                    <img v-show="item.is_real" class="real" src="../../website/assets/img/real.svg" alt="" srcset=""
                crossOrigin="anonymous" />
                  </div>
                  <div class="addr h v_center">
                    <span>{{ item.distance_name }}</span>
                    <span> · {{ item.distance }}km</span>
                  </div>
                </div>
              </div>
              <div class="tagCon v">
                <div class="nums h v_center">
                  <img src="../../website/assets/img/notice.svg" />
                  Ta有
                  <span class="count">{{ item.total_tags_num }}</span>
                  项索引数据，
                  <span class="identical">{{ item.contacts_num }}</span>
                  项第三方数据
                </div>
                <div class="tagList relativeoh h wrap">
                  <span class="h v_center" v-for="(value,idx) in item.tags" :key="idx">
                    <img src="../../website/assets/img/authenticationIcon.svg" v-if="value[1] == 1" />
                    {{value[0]}}
                  </span>
                </div>
              </div>
              <div class="linksCon h v_center relative">
                <div class="dl h v_center relative">
                  <div class="dd relative" v-for="(links,idx) in item.linksArr" :key="idx">
                    <img class="radius50" :src="links" />
                  </div>
                  <div class="dd relative" v-if="item.linksNums>5">
                    <img class="radius50" src="../../website/assets/img/more.svg" />
                  </div>
                </div>
                <span><i v-if="item.linksNums>5">等</i>{{item.linksNums}}项数据</span>
              </div>
            </div>
            <button class="moreBtn h_center" @click="more" v-if="isMobile">查看更多</button>
          </div>
          <div class="h wrap loveUl" ref="loveUl" v-if="resultList && resultList.length > 0 && currRole == 'love'">
            <waterfall
              :col="col"
              :data="resultList"
              :gutterWidth="gutterWidth"
            >
              <template>
                <div class="li relative" v-for="(item,index) in resultList" :key="index" @click="detailsClick(item.user_meta.link_num)">
                  <div class="userCon v">
                    <img class="avatar" :src="item.user_meta.cover_url" v-if="item.user_meta.cover_url" />
                    <img class="avatar" :src="item.photos[0].raw" v-else-if="item.photos && item.photos.length>0" />
                    <img class="avatar" :src="item.avatar" v-else />
                    <div class="userInfo v">
                      <div class="names h v_center">
                        {{item.name}}
                        <div class="age h v_center" :class="{'age2':item.sex == '男'}">
                          <img src="../../website/assets/img/female_color.png" v-show="item.sex == '女'" />
                          <img src="../../website/assets/img/male_color.svg" v-show="item.sex == '男'" />
                          {{item.age}}
                        </div>
                      </div>
                      <div class="addr">
                        {{item.distance_name}} · {{item.distance}}km
                      </div>
                      <div class="linksCon h v_center relative">
                        <div class="dl h v_center relative">
                          <div class="dd relative" v-for="(links,idx) in item.linksArr" :key="idx">
                            <img class="radius50" :src="links" />
                          </div>
                          <div class="dd relative" v-if="item.linksNums>5">
                            <img class="radius50" src="../../website/assets/img/more.svg" />
                          </div>
                        </div>
                        <span><i v-if="item.linksNums>5">等</i>{{item.linksNums}}项数据</span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </template>
            </waterfall>
          </div>
        </div>
        <div class="moreTips h_center" @click="dow()" v-if="!isMobile">
          到<span>如尔App</span>查看更多
        </div>
        <div class="moreTips h_v_center" :class="{'shakeX':isAnimate}" @click="dow()" v-if="isMobile && isAnimate">
          <img class="iconl" src="../../website/assets/img/tips.svg" />
          还有一百多种高级筛选请<span>下载App</span>使用！
          <img class="iconr" src="../../website/assets/img/closebai.svg" />
        </div>
      </div>
      <Footer :isMobile="isMobile" />
    </div>
  </div>
</template>
<script>
import Nav from "../nav/Nav.vue";
import Footer from "../footer/Footer.vue";
import axios from "../../utils/axios.js";
export default {
  data() {
    return {
      col:6,//瀑布流列
      isMobile: false,
      isImg1: false,
      isImg2: true,
      isImgBtn: false,
      isImgBtn1: true,
      isImgBtn2: false,
      currSelectIdx:[0,0,0,0],
      selectList:[
      {"names":"性别要求","values":["不限","男生","女生"]},
      {"names":"年龄要求","values":["不限","20-25","26-30","31-35","36-40","41-45","46-50","50以上"]},
      {"names":"学历要求","values":["不限","大学专科","大学本科","高中及以下","硕士研究生","博士研究生"]},
      {"names":"星座要求","values":["不限","白羊","金牛","双子","巨蟹","狮子","处女","天秤","天蝎","射手","摩羯","水瓶","双鱼"]},
      {"names":"高级筛选仅App可用","values":["家乡","属相","身高","体重","BMI","颜值","学校","专业","行业","职业","年收入","爱好","运动","游戏","感情状态","婚姻状态","特质","MBTI类型","EQ等级","IQ等级","芝麻信用等级","是否有房","是否有车"]},
      ],
      currTagList:[],
      isAnimate:false,
      resultNum:0,
      resultList:[],
      isScrollTop: false,
      isRole:false,//roleArr 显示当前身份选项
      currRole:'common',//当前身份值
      selectArr:[],//搜索类型
      currSelect:-1,//selectArr idx //当前点击搜索类型idx
      isSelect:false,//selectListMain  //显示当前点击搜索类型选项
      optionsArr:['男','女'],//当前点击搜索类型选项arr
      optionsArrIdx:-1,//当前点击搜索类型选项选项arr选中idx
      ageArr:[16,45],//年龄
      isDisabled:false,
      addlat:'30.49984',
      addlng:'114.34253',
    };
  },
  mounted() {
    this.userAgent()
    if(this.isMobile){
      this.col = 2
    }
    this.getList()
    this.getPosition()
    
    // 监听页面滚动事件
    if (!this.isMobile) {
      window.addEventListener('scroll', this.handleScroll, true)
    }
    
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    if (!this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    //获取当前定位  Key和referer修改为自己项目的值
    getPosition(){
      var geolocation = new window.qq.maps.Geolocation('HMPBZ-O3V36-3H7SM-MIUN6-TPDGS-ZSBV4', 'role');
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    showPosition(position){
      this.addlat = position.lat
      this.addlng = position.lng
      this.getList()
    },
    //定位失败
    errorPosition(){
      console.log('定位失败');//继续定位
      this.getPosition();
    },
    more(){
      this.isAnimate = true
      setTimeout(() => {
        this.isAnimate = false
      }, 1500);
    },
    clearClick(){
      if(this.currRole == 'love'){
        this.currSelectIdx = [2,0,0,0]
      }else{
        this.currSelectIdx = [0,0,0,0]
      }
      this.getList()
    },
    /* selectRoles(){
      this.isSelect = !this.isSelect
      if(this.currRole == 'common'){
        this.currRole = 'love'
        this.resultList = []
        this.$set(this.currSelectIdx,0,2)
        this.isDisabled = true
      }else{
        this.currRole = 'common'
        this.isDisabled = false
      }
      this.getList()
    }, */
    seletCurrRole(e){
      if(e == 1){
        this.currRole = 'common'
        this.isDisabled = false
      }else{
        this.currRole = 'love'
        this.resultList = []
        this.$set(this.currSelectIdx,0,2)
        this.isDisabled = true
      }
      this.getList()
    },
    async getList(){
      if(!this.isMobile){
        if(this.currSelectIdx[0]>0){
          this.currTagList[0] = this.selectList[0].values[this.currSelectIdx[0]].slice(0,1)
        }else{
          this.currTagList[0] = ''
        }
        if(this.currSelectIdx[1]>0){
          if(this.currSelectIdx[1]>6){
            this.currTagList[1] = '50-200岁'
          }else{
            this.currTagList[1] = this.selectList[1].values[this.currSelectIdx[1]]+'岁'
          }
        }else{
          this.currTagList[1] = ''
        }
        if(this.currSelectIdx[2]>0){
          this.currTagList[2] = this.selectList[2].values[this.currSelectIdx[2]]
        }else{
          this.currTagList[2] = ''
        }
        if(this.currSelectIdx[3]>0){
          this.currTagList[3] = this.selectList[3].values[this.currSelectIdx[3]]+'座'
        }else{
          this.currTagList[3] = ''
        }
        this.currTagList = this.currTagList.filter((item)=>{ return item && item.trim() })
      }else{
        let sexItem = -1
        let itemArr = []
        this.selectArr.forEach((item,index)=>{
          if(index == 0){
            if(item == '男'){
              sexItem = 0
            }else if(item == '女'){
              sexItem = 1
            }
          }
          if(index == 1){
            item = item + '岁'
          }
          if(index == 3){
            item = item + '座'
          }
          if(item){
            itemArr.push(item)
          }
        })
        this.currTagList = itemArr
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.resultList = []
      this.resultNum = 0
      // console.log(this.currTagList);
      let res = await axios({
        method: "get",
        url: "/v2/users/search",
        params: {
          page: 1,
          page_size: 30,
          tags: this.currTagList,
          role: this.currRole,
          lat: this.addlat,
          lng: this.addlng,
        },
      });
      if(res.status == '200'){
        this.resultNum = res.data.meta.total
        res.data.users.forEach(item => {

          let tagSexIdx = item.tags.findIndex((items) => items[3] == 'sex')
          if (tagSexIdx > -1) {
            let firstItem = item.tags[tagSexIdx]
            item.tags.splice(tagSexIdx, 1)
            item.tags.unshift(firstItem)
          } else {
            let isReal = item.is_real ? 1 : -1
            let sexItems = [item.sex, isReal, 1, 'sex']
            item.tags.unshift(sexItems)
          }
          //排序 年龄2
          let tagAgeIdx = item.tags.findIndex((items) => items[3] == 'age')
          if (tagAgeIdx > -1) {
            let firstItem = item.tags[tagAgeIdx]
            item.tags.splice(tagAgeIdx, 1)
            item.tags.splice(1, 0, firstItem)
          } else {
            let isReal = item.is_real ? 1 : -1
            let ageItems = [`${item.age}岁`, isReal, 2, 'age']
            item.tags.splice(1, 0, ageItems)
          }
          item.tags = item.tags.slice(0,12)

          if(item.contacts_num>0 || item.link_count>0){
            item.linksNums = item.contacts_num+item.link_count
            let currArrs = []
            currArrs = currArrs.concat(item.contact_url).concat(item.link_url)
            if(this.isMobile && this.currRole == 'love'){
              item.linksArr = currArrs.slice(0,2)
            }else{
              item.linksArr = currArrs.slice(0,5)
            }
            
          }
        });
        this.resultList = res.data.users
      }
    },
    listClick(items,index,idx){
      if(index != 4){
        if(this.currRole == 'love' && index == 0 && idx == 0){
          console.log('脱单身份不能选性别不限');
          return false;
        }
        this.$set(this.currSelectIdx,index,idx)
        // console.log(idx);
        console.log(this.currSelectIdx);
        this.getList()
      }else{
        this.isAnimate = true
        setTimeout(() => {
          this.isAnimate = false
        }, 1500);
      }
    },
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
    handleScroll() {
      this.$nextTick(() => {
        if (document.documentElement.scrollTop > 100) {
          this.isScrollTop = true;
        } else {
          this.isScrollTop = false;
        }
      });
    },
    selectRole(){
      if(this.currRole == 'love'){
        this.currRole = 'common'
        this.$set(this.selectArr,0,'女')
        
      }else{
        this.currRole = 'love'
      }
      this.getList()
    },
    reset(){
      if(this.currRole == 'love' && this.currSelect == 0){
        return false
      }
      this.optionsArrIdx = -1
      this.ageArr = [16,45]
    },
    sure(){
      if(this.currSelect == 1){
        this.selectArr[1] = this.ageArr[0] + '-' +this.ageArr[1]
      }else{
        this.selectArr[this.currSelect] = this.optionsArr[this.optionsArrIdx]
      }
      this.isSelect = false
      this.currSelect = -1
      this.getList()
    },
    selectClick(e){
      this.isSelect = true
      if(this.currSelect != e){
        this.optionsArrIdx = -1
      }
      this.currSelect = e
      if(e == 1){
        this.optionsArr = []
        if(this.selectArr[1]){
          this.ageArr[0] = this.selectArr[1].slice(0,2)
          this.ageArr[1] = this.selectArr[1].slice(-2)
        }
      }else{
        if(e == 0){
          this.optionsArr = ['男','女']
        }else if(e == 2){
          this.optionsArr = ['小学学历','初中学历','高中学历','大学专科','大学本科','硕士研究生','博士研究生']
        }else if(e == 3){
          this.optionsArr = ["白羊","金牛","双子","巨蟹","狮子","处女","天秤","天蝎","射手","摩羯","水瓶","双鱼"]
        }
        for (let a = 0; a < this.optionsArr.length; a++) {
          if(this.optionsArr[a] == this.selectArr[this.currSelect]){
            this.optionsArrIdx = a
            break;
          }
        }
      }
    },
    optionsClick(item,index){
      if(this.optionsArrIdx == index){
        this.optionsArrIdx = -1
      }else{
        this.optionsArrIdx = index
      }
    },
    onChange(value) {
      // console.log(value);
    },
    close(){
      this.isSelect = false
      this.currSelect = -1
      this.optionsArrIdx = -1
    },
    detailsClick(links){
      window.open('https://role.cn/'+links)
    },
    userAgent() {
      //如果是电脑，则判断电脑的操作系统
      function detectOS() {
        let sUserAgent = navigator.userAgent;
        let isWin =
          navigator.platform == "Win32" || navigator.platform == "Windows";
        let isMac =
          navigator.platform == "Mac68K" ||
          navigator.platform == "MacPPC" ||
          navigator.platform == "Macintosh" ||
          navigator.platform == "MacIntel";
        if (isMac) return "pc";
        let isUnix = navigator.platform == "X11" && !isWin && !isMac;
        if (isUnix) return "pc";
        let isLinux = String(navigator.platform).indexOf("Linux") > -1;
        if (isLinux) return "pc";
        if (isWin) {
          let isWin2K =
            sUserAgent.indexOf("Windows NT 5.0") > -1 ||
            sUserAgent.indexOf("Windows 2000") > -1;
          if (isWin2K) return "pc";
          let isWinXP =
            sUserAgent.indexOf("Windows NT 5.1") > -1 ||
            sUserAgent.indexOf("Windows XP") > -1;
          if (isWinXP) return "pc";
          let isWin2003 =
            sUserAgent.indexOf("Windows NT 5.2") > -1 ||
            sUserAgent.indexOf("Windows 2003") > -1;
          if (isWin2003) return "pc";
          let isWinVista =
            sUserAgent.indexOf("Windows NT 6.0") > -1 ||
            sUserAgent.indexOf("Windows Vista") > -1;
          if (isWinVista) return "pc";
          let isWin7 =
            sUserAgent.indexOf("Windows NT 6.1") > -1 ||
            sUserAgent.indexOf("Windows 7") > -1;
          if (isWin7) return "pc";
          let isWin8 =
            sUserAgent.indexOf("Windows NT 8.0") > -1 ||
            sUserAgent.indexOf("Windows 8") > -1;
          if (isWin8) return "pc";
          let isWin10 =
            sUserAgent.indexOf("Windows NT 10.0") > -1 ||
            sUserAgent.indexOf("Windows 10") > -1;
          if (isWin10) return "pc";
        }
        return "other";
      }
      // alert("您的操作系统是：" + detectOS()); //判断是手机还是电脑，什么浏览器，手机操作系统
      let browser = (function () {
        let ua = navigator.userAgent;
        let isWindowsPhone = /(?:Windows Phone)/.test(ua);
        let isSymbian = /(?:SymbianOS)/.test(ua);
        let isAndroid = /(?:Android)/.test(ua);
        let isFireFox = /(?:Firefox)/.test(ua);
        let isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua));
        let isPhone = /(?:iPhone)/.test(ua) && !isTablet;
        let isChrome = /(?:Chrome|CriOS)/.test(ua);
        let isMobile = isWindowsPhone || isSymbian || isAndroid || isPhone;
        let isPc = !isMobile;
        return {
          isMobile: isMobile,
          isPc: isPc,
          isPhone: isPhone, //苹果
          isWindowsPhone: isWindowsPhone, //微软手机
          isSymbian: isSymbian, //班赛
          isAndroid: isAndroid, //安卓
          isFireFox: isFireFox,
          isChrome: isChrome,
          isTablet: isTablet, //平板
        };
      })();
      let visit_type; //手机or电脑or平板
      let userBrowser; //浏览器
      let userOs; //操作系统
      if (browser.isPc) {
        //电脑
        visit_type = "pc"; //如果是电脑则判断其操作系统
        userOs = detectOS();
        if (browser.isFireFox) {
          userBrowser = "火狐";
        } else if (browser.isChrome) {
          userBrowser = "谷歌";
        } else {
          userBrowser = "ie";
        }
      } else if (browser.isMobile) {
        //手机
        visit_type = "手机";
        userBrowser = "手机应用";
        if (browser.isPhone) {
          userOs = "手机";
        } else if (browser.isWindowsPhone) {
          userOs = "手机";
        } else if (browser.isSymbian) {
          userOs = "手机";
        } else if (browser.isAndroid) {
          userOs = "手机";
        } else {
          userOs = "手机";
        }
      } else if (browser.isTablet) {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      } else {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      }
      this.isMobile = browser.isMobile
    },
  },
  computed:{
    itemWidth(){  
      return parseInt(348*0.5*(document.documentElement.clientWidth/375))
    },
    gutterWidth(){
      if(this.isMobile){
        console.log(parseInt(14*0.5*(document.documentElement.clientWidth/375)));
        return parseInt(14*0.5*(document.documentElement.clientWidth/375))
      }else{
        return 7
      }
      
    }
  },
  components: {
    Nav,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.searchList2 {
  @include scss_wh(100%, auto);
  background: #fff;
  .searchListCon {
    @include scss_wh(1128px, auto);
    margin: 0 auto;
    .moreTips{
      @include scss_fch(20px, #000, 29px);
      margin:35px auto 100px;
      cursor: pointer;
      span{
        color:#2A82E4;
      }
    }
    .resultCon{
      .moreBtn{
        padding:20px 0;
      }
      .loveUl{
        background:#F0FBF0;
        padding: 22px 18px;
        .li{
          background:#fff;
          border-radius:8px;
          overflow: hidden;
          margin-bottom:8px;
          cursor: pointer;
          .avatar{
            width: 100%;
            height: auto;
            min-height: 130px;
            max-height: 232px;
            object-fit: cover;
          }
          .userInfo{
            padding: 5px 8px 10px;    
            .addr{
              @include scss_fch(12px, #999, 17px);
            }
            .names{
              @include scss_fch(15px, #333, 22px);
              margin-bottom: 2px;
              
              .age{
                padding: 0px 4px 0 0;
                @include scss_whbb(auto, 14px,#FF69B4,7px);
                @include scss_fch(10px, #fff, 14px);
                margin-left: 6px;
                img{
                  @include scss_wh(14px, 14px);
                }
              }
              .age2{
                background: #0097FF;
              }
            }
            .linksCon{
              margin-top: 10px;
              padding: 10px 0 0;
              border-top: solid 1px rgba(0,0,0,0.05);
              span{
              @include scss_fch(11px, #999, 16px);
              margin-left: 8px;
            }
            .dl{
              margin-left: 4px;
              .dd{
                @include scss_wh(22px, 22px);
                margin-left: -4px;
                img{
                  @include scss_wh(22px, 22px);
                }
              }
            }
            }
          }
        }
      }
      .commonUl{
        background:#F0FBF0;
        padding: 20px 20px 25px;
        .li{
          @include scss_whbb(343px, auto,#fff,8px);
          padding: 12px 16px 13px;
          margin-right:29px;
          margin-bottom:10px;
          .tagCon{
            padding: 10px 0;
            border-bottom: solid 1px rgba(0,0,0,0.05);
            margin-bottom: 10px;
            .tagList{
              height:56px;
              span{
                padding: 0 8px;
                @include scss_whbb(auto, 24px,#F5F5F7,12px);
                @include scss_fch(13px, #666, 24px);
                margin-right:8px;
                margin-bottom:8px;
                img{
                  @include scss_wh(14px, 14px);
                  margin-right:1px;
                }
              }
            }
            .nums{
              @include scss_fch(12px, #333, 17px);
              margin-bottom:9px;
              .count{
                color:#0076FF;
              }
              .identical{
                color:#00C975;
              }
              img{
                @include scss_wh(11px, 11px);
                margin-right:7px;
              }
            }
          }
          .linksCon{
            span{
              @include scss_fch(11px, #999, 16px);
              margin-left: 8px;
            }
            .dl{
              margin-left: 4px;
              .dd{
                @include scss_wh(22px, 22px);
                margin-left: -4px;
                img{
                  @include scss_wh(22px, 22px);
                }
              }
            }
          }
          .userCon{
            border-bottom:solid 1px rgba(0, 0, 0, 0.05);
            padding-bottom:12px;
            .names{
              @include scss_fch(15px, #333, 22px);
              margin-bottom:4px;
              .real{
                @include scss_wh(15px, 15px);
                margin-left:4px;
              }
            }
            .addr{
              @include scss_fch(11px, #999, 16px);
            }
            img{
              @include scss_wh(40px, 40px);
              margin-right:12px;
            }
          }
        }
        .li:nth-child(3n){
          margin-right:0;
        }
      }
      .titles{
        @include scss_fch(20px, #000, 34px);
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .searchSelect{
      @include scss_whbb(100%, auto, #FFFCE5,6px);
      padding: 32px 64px 19px;
      margin-bottom: 30px;
      .tips{
        @include scss_fch(18px, #2A82E4, 26px);
        font-weight: 500;
        cursor: pointer;
      }
      .ul{
        .li{
          .types{
            @include scss_fch(14px, #808080, 22px);
            margin-right: 30px;
            margin-bottom: 20px;
            flex-shrink: 0;
          }
          .dl{
            margin-bottom: 2px;
            .dd{
              @include scss_fch(14px, #808080, 22px);
              margin-bottom: 20px;
              padding: 0 8px;
              cursor: pointer;
            }
            .dd.active{
              @include scss_whbb(auto, 22px, #FFE600,5px);
            }
          }
          .dl.isDisabled{
            .dd:first-child{
              cursor: no-drop;
              color: #B5B5B5;
            }
          }
        }
        .li1,.li2,.li3{
          .dl{
            .dd{
              margin-right: 20px;
            }
            .dd:first-child{
              margin-right: 40px;
            }
          }
        }
        .li4{
          .dl{
            .dd{
              margin-right: 16px;
            }
            .dd:first-child{
              margin-right: 40px;
            }
          }
        }
        .li5{
          .types{
            color: #D43030;
            margin-right: 45px;
          }
          .dl{
            .dd{
              margin-right: 10px;
              color: #B5B5B5;
              cursor: no-drop;
            }
          }
        }
      }
      .roles{
        margin-bottom: 38px;
        .roleCon{
          cursor: pointer;
          margin-left: 33px;
          span{
            width: 100px;
            text-align: center;
            @include scss_fcwh(22px, #B5B5B5, 29px);
          }
          .currRole{
            font-size:24px;
            color:#000;
          }
        }
        .options{
          @include scss_alt(50%,47px);
          transform: translateX(-50%);
          background: #F4F6FA;
          border-radius: 16px;
          padding: 10px 20px;
          span{
            @include scss_fcwh(14px, #000, 29px);
          }
          em{
            @include scss_wh(0, 0);
            border-width: 0 16px 16px;
            border-style: solid;
            border-color: transparent transparent #F4F6FA;
            top: -14px;
          }
        }
        .clear{
          @include scss_fch(14px, #808080, 22px);
          @include scss_art(0,0);
          z-index: 9;
          cursor: pointer;
          font-weight: normal;
        }
        img{
          @include scss_wh(25px, 25px);
          margin-left: 10px;
          // transform: rotate(90deg);
        }
      }
    }
    .searchTop {
      margin: 136px auto 86px;
      .title1{
        @include scss_fcwh(40px, #121127, 56px);
        text-align: center;  
      }
      .title2 {
        @include scss_fch(14px, rgba(18, 17, 39, 0.72), 32px);
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
.searchList {
  @include scss_wh(100%, auto);
  background: #F4F6FA;
  .header{
    @include scss_whbb(100%, 0.68rem,#fff);
    @include scss_plt(fixed,0,1.5rem);
    z-index: 99;
    .selectRoles{
      @include scss_wh(100%, 0.68rem);
      padding: 0 0.32rem;
      span{
        margin-right: 0.36rem;
        @include scss_fch(0.3rem, #666, 0.42rem);
      }
      span.active{
        @include scss_fch(0.4rem, #000, 0.56rem);
      }
    }
    .conb{
      @include scss_wh(100%, 0.68rem);
      padding: 0 0 0 0.08rem;
      ul{
        width:calc(100% - 1.08rem);
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 0;
          display: none;
        }
        li{
          @include scss_whbb(auto, 0.52rem,#fff,0.26rem);
          border: solid 1px #fff;
          padding: 0 0.25rem;
          margin-right: 0.1rem;
          flex-shrink:0;
          span{
            @include scss_fch(0.26rem, #333, 0.38rem);
            margin-right: 0.02rem;
          }
          img{
            @include scss_wh(0.12rem, 0.12rem);
          }
          .icon0{
            @include scss_wh(0.2rem, 0.2rem);
          }
        }
        li:first-child{
          border:0;
          padding: 0 0.15rem 0 0.25rem;
          font-weight: bold;
        }
        li.active{
          background: #FFF9D2;
          border: solid 1px #FBE54D;
        }
        li.selected{
          height: 0.62rem;
          padding-bottom: 0.1rem;
          border-radius: 0.26rem 0.26rem 0 0; 
          background:#F4F6FA !important;
          border:none !important;
        }
      }
      .conbr{
        @include scss_wh(1.08rem, 0.44rem);
        @include scss_art(0,0.12rem);
        border-left: 1px solid #E6E6E6;
        flex-shrink: 0;
        span{
          @include scss_fch(0.26rem, #333, 0.36rem);
          margin-right: 0.04rem;
        }
        img{
          @include scss_wh(0.2rem, 0.2rem);
        }
      }
    }
    .roleArr{
      position: fixed;
      z-index: 99;
      .bgBox{
        @include scss_whbb(100%, 100%,rgba(0,0,0,0.4));
        @include scss_alt(0,0);
        z-index: -1;
      }
      .roleArrCon{
        @include scss_alt(0rem,2.08rem);
        width: 1.75rem;
        background: #F4F6FA;
        border-radius: 0.16rem;
        padding: 0.1rem 0.1rem;
        em{
          @include scss_wh(0, 0);
          border-width: 0 0.16rem 0.16rem;
          border-style: solid;
          border-color: transparent transparent #F4F6FA;
          top: -0.14rem;
        }
        span{
          @include scss_fch(0.26rem, #333, 0.46rem);
          img{
            @include scss_wh(0.2rem, 0.2rem);
            margin-left: 0.05rem;
          }
        }
        .bor{
          border-bottom: solid 1px rgba(0, 0, 0, 0.05);
        }
      }
      
    }
    .selectListMain{
      @include scss_wh(100%, 100%);
      position: fixed;
      .bgBox{
        @include scss_whbb(100%, 100%,rgba(0,0,0,0.4));
        @include scss_alt(0,0);
        z-index: -1;
      }
    }
    .selectList{
      @include scss_whbb(100%, auto,#F9FAFC,0px 0px 0.24rem 0.24rem);
      padding: 0.1rem 0.32rem 0.35rem;
      .ageArr{
        padding: 0;
        height: 24px;
        margin: 0.32rem;
        ::v-deep .van-slider{
          .custom-button{
            width: 24px;
            height: 24px;
            background: #FFE600;
            border-radius: 50%;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #666;
          }
        }
        
      }
      ul{
        li{
          @include scss_wh(50%, 0.72rem);
          span{
            @include scss_fch(0.26rem, #333, 0.36rem);
            margin-right: 0.04rem;
          }
          img{
            @include scss_wh(0.2rem, 0.2rem);
          }
        }
        li:nth-child(2n){
          padding-left: 0;
        }
        li.active{
          span{
            font-weight: bold;
            color: #000;
          }
        }
      }
      .btnCon{
        margin-top: 0.3rem;
        .btn{
          @include scss_wh(47.5%, 0.68rem);
          border-radius: 0.34rem;
        }
        .btn1{
          border: solid 1px #666;
          @include scss_fcwh(0.26rem, #333, 0.36rem);
        }
        .btn2{
          background: #FFE600;
          @include scss_fcwh(0.26rem, #333, 0.36rem);
        }
      }
    }
  }
  .searchListCon{
    margin-top:1.32rem;
    min-height:90vh;
    .resultCon{
      padding-top:1.08rem;
      .moreBtn{
        padding:20px 0;
        width:100%;
      }
    }
  }
  .commonUl{
    width:91.46%;
    margin:0 auto;
    .li{
      @include scss_whbb(100%, auto,#fff,0.16rem);
      min-height: 2.18rem;
      padding: 0.24rem 0.32rem 0.25rem 0.32rem;
      margin-bottom: 0.2rem;
      .userCon{
        padding-bottom: 0.21rem;
        margin-bottom: 0.25rem;
        border-bottom: solid 1px rgba(0,0,0,0.05);
        .avatar{
          @include scss_wh(0.8rem, 0.8rem);
          margin-right: 0.24rem;
        }
        .names{
          @include scss_fcwh(0.3rem, #000, 0.44rem);
          .real{
            @include scss_wh(0.3rem, 0.3rem);
            margin-left:0.08rem;
          }
        }
        .addr{
          @include scss_fch(0.22rem, #999, 0.32rem);
        }
      }
      .tagCon{
        margin-bottom: 0.21rem;
        padding-bottom: 0.19rem;
        border-bottom: solid 1px rgba(0,0,0,0.05);
        .nums{
          margin-bottom: 0.16rem;
          img{
            @include scss_wh(0.32rem, 0.32rem);
            margin-right: 0.08rem;
          }
          @include scss_fch(0.24rem, #333, 0.34rem);
          font-weight: 500;
          .count{
            color: #0076FF;
          }
          .identical{
            color: #00C975;
          }
        }
      }
      .linksCon{
        span{
          @include scss_fch(0.22rem, #999, 0.32rem);
          margin-left: 0.16rem;
        }
        .dl{
          margin-left: 0.08rem;
          .dd{
            @include scss_wh(0.42rem, 0.42rem);
            margin-left: -0.08rem;
            img{
              @include scss_wh(0.42rem, 0.42rem);
            }
          }
        }
      }
      .tagList{
        height: 1rem;
        overflow: hidden;
        span{
          display: inline-flex;
          padding: 0.06rem 0.16rem;
          background: #f4f6fa;
          border-radius: 0.24rem;
          @include scss_fch(0.26rem, #666);
          margin-right: 0.16rem;
          margin-bottom: 0.16rem;
          img{
            @include scss_wh(0.28rem, 0.28rem);
            margin-right: 0.02rem;
          }
        }
        span.active{
          background: #FFF9D2;
          border: solid 1px #FBE54D;
        }
      }
    }
  }
  .loveUl{
    padding: 0.16rem 0.2rem 0;
    min-height: 100vh;
    .li{
        height: auto;
        background: #fff;
        border-radius: 0.16rem;
        margin-bottom:0.16rem;
        overflow: hidden;
        .userInfo{
          @include scss_wh(100%, auto);
          padding: 0.12rem 0.16rem 0.14rem;
          .names{
            @include scss_fcwh(0.3rem, #000, 0.42rem);
            margin-bottom: 0.04rem;
            .age{
              @include scss_fch(0.2rem, #fff, 0.28rem);
              @include scss_whbb(auto, 0.28rem,#FF69B4,7px);
              padding: 0px 0.08rem 0 0;
              margin-left: 0.24rem;
              font-weight: normal;
              img{
                @include scss_wh(0.28rem, 0.28rem);
              }
            }
            .age2{
              background: #0097FF;
            }
          }
          .addr{
            @include scss_fch(0.24rem, #999, 0.34rem);
          }
          .linksCon{
            margin-top: 0.1rem;
            padding: 0.13rem 0 0;
            border-top: solid 1px rgba(0,0,0,0.05);
            span{
              @include scss_fch(0.22rem, #999, 0.32rem);
              margin-left: 0.16rem;
            }
            .dl{
              margin-left: 0.08rem;
              .dd{
                @include scss_wh(0.42rem, 0.42rem);
                margin-left: -0.08rem;
                img{
                  @include scss_wh(0.42rem, 0.42rem);
                }
              }
            }
          }
        }
        .avatar{
          @include scss_wh(100%, auto);
          height: auto;
          min-height: 2.6rem;
          max-height: 4.64rem;
          object-fit: cover;
        }
        
      }
  }
  .moreTips{
    @include scss_alb(10.7%,1.38rem);
    position: fixed;
    z-index: 1000;
    @include scss_whbb(78.6%, 0.8rem,#3C3C3C,0.4rem);
    @include scss_fch(0.24rem, #fff, 0.35rem);
    .iconl{
      @include scss_wh(0.5rem,0.5rem);
      margin-left:0.38rem;
      margin-right:0.06rem;
    }
    .iconr{
      @include scss_wh(0.3rem,0.3rem);
      margin-right:0.38rem;
    }
    span{
      color:#2A82E4;
    }
  }
}
img {
  max-width: 100%;
}
//导航
::v-deep .subNav{
  background:#fff;
}
::v-deep .nav {
  i {
    @include scss_alb(167px, -5px);
  }
  #search {
    font-weight: 600;
  }
}
::v-deep .subNavContent{
  #search {
    font-weight: 600;
  }
}
//swiper
#app {
  width: 100%;
  background: #fff;
}
.flex-auto {
  flex: 1 1 auto;
}
.shrink-0 {
  flex-shrink: 0;
}
@-webkit-keyframes shakeX {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  30%,
  70%{
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }
  20%,
  60%{
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }
}
@keyframes shakeX {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  30%,
  70% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }
  20%,
  60%{
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }
}
.shakeX {
  -webkit-animation: shakeX 0.5s ease forwards;
  animation: shakeX 0.5s ease forwards;
}
</style>