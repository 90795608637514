// 最底层封装 get和post 设置默认地址 请求拦截器 响应拦截器
import axios from "axios"

// 设置默认地址
// axios.defaults.baseURL = "/imlinkapp"
axios.defaults.baseURL = "https://api.role.cn/"

//设置请求拦截器
axios.interceptors.request.use((config) => {
    let tk = '82a31601951c23da06756aff34aa520f'
    // tk = localStorage.getItem('token')
    if (config.method === "get") {//响应头显示设置
        config.data = true
        config.headers = {
            'Content-Type': 'application/json; charset=utf-8',
            // 'authorization': tk
        }
    }
    //判断 如果token存在，设置在请求头中
    if (tk) {			//如果tk存在，往下执行 不存在就停止 return
        config.headers.Authorization = tk;
    }
    return config;
}, (res) => {
    return Promise.reject(res)
})

// 设置响应拦截器
axios.interceptors.response.use((config) => {
    return config;
}, (res) => {
    return res.response
})

export default axios