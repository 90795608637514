<template>
  <div id="app" class="tlrb">
    <Nav :isMobile="isMobile" :types="2" @audioClick="audioClick" />
    <!-- 数据-->
    <div class="relative" :class="[isMobile?'idea2':'idea']">
      <Home :isAudio="isAudio" :isMobile="isMobile" />
      <div class="ideaCon relative">
        <div class="details">
          <div class="titles1">Our view</div>
          <div class="titles2">我们的Web3理念</div>
          <div class="ideaConR" v-show="isMobile"> 
            <img src="../../website/assets/img/idea/idea1.png" />
            <div class="span">OUR TEAM</div>
          </div>
          <p>用户数据是信息社会最重要的生产要素，无数种商业场景，比如广告、交友、招聘等，都依赖用户数据完成连接和交易。长期以来，用户本人从未掌握过自己的数据，反而是互联网平台从我们的数据中攫取了巨额利益。</p>
          <p>如尔团队坚定支持Web3愿景，即用户应该可以管理自己在平台存储的数据，数据只有经用户授权，才能被第三方使用，更重要的，只有用户本人有资格为自己的数据定价，并获得收益。</p>
          <p>
            因此，我们开发了如尔App，这是一款底层逻辑创新的通讯应用。我们提供一个基于区块链的数据库，由用户上传并认证信息，然后将不同的数据授权到不同的身份，应用于不同的商业场景。每个身份都有唯一的身份地址，每个人都能自由检索数据，找到需求的彼此，并按照对方预设的价格，点对点直接连接，从而实现数据的合规流通和去中心化的信息分发。
          </p>
          <p>
            需要特别说明的是，Web3带给用户的，绝不是眼前的一点现金收益，而是生产关系革命后，生产力的飞升——具体到互联网，就是连接将更加低成本、高效率。当每个人都能平等的使用所有人的数据，就像被赋予上帝视角，将开启一场新的信息革命。
          </p>
        </div>
        <div class="ideaConR" v-show="!isMobile"> 
          <img src="../../website/assets/img/idea/idea1.png" />
          <div class="span">OUR TEAM</div>
        </div>
      </div>
      <Footer :isMobile="isMobile" />
    </div>
  </div>
</template>

<script>
import Nav from "../nav/Nav.vue";
import Home from "../home/Home.vue";
import Footer from "../footer/Footer.vue";
export default {
  data() {
    return {
      isAudio: false, //声音
      isImg1: true,
      isImg2: false,
      isImgBtn: false,
      isImgBtn1: true,
      isImgBtn2: false,
      isMobile: false,
    };
  },
  mounted() {

    this.userAgent();
    console.log(this.isMobile)

  },
  methods: {
    audioClick(e){
      console.log(e);
      this.isAudio = e
    },
    userAgent() {
      //如果是电脑，则判断电脑的操作系统
      function detectOS() {
        let sUserAgent = navigator.userAgent;
        let isWin =
          navigator.platform == "Win32" || navigator.platform == "Windows";
        let isMac =
          navigator.platform == "Mac68K" ||
          navigator.platform == "MacPPC" ||
          navigator.platform == "Macintosh" ||
          navigator.platform == "MacIntel";
        if (isMac) return "pc";
        let isUnix = navigator.platform == "X11" && !isWin && !isMac;
        if (isUnix) return "pc";
        let isLinux = String(navigator.platform).indexOf("Linux") > -1;
        if (isLinux) return "pc";
        if (isWin) {
          let isWin2K =
            sUserAgent.indexOf("Windows NT 5.0") > -1 ||
            sUserAgent.indexOf("Windows 2000") > -1;
          if (isWin2K) return "pc";
          let isWinXP =
            sUserAgent.indexOf("Windows NT 5.1") > -1 ||
            sUserAgent.indexOf("Windows XP") > -1;
          if (isWinXP) return "pc";
          let isWin2003 =
            sUserAgent.indexOf("Windows NT 5.2") > -1 ||
            sUserAgent.indexOf("Windows 2003") > -1;
          if (isWin2003) return "pc";
          let isWinVista =
            sUserAgent.indexOf("Windows NT 6.0") > -1 ||
            sUserAgent.indexOf("Windows Vista") > -1;
          if (isWinVista) return "pc";
          let isWin7 =
            sUserAgent.indexOf("Windows NT 6.1") > -1 ||
            sUserAgent.indexOf("Windows 7") > -1;
          if (isWin7) return "pc";
          let isWin8 =
            sUserAgent.indexOf("Windows NT 8.0") > -1 ||
            sUserAgent.indexOf("Windows 8") > -1;
          if (isWin8) return "pc";
          let isWin10 =
            sUserAgent.indexOf("Windows NT 10.0") > -1 ||
            sUserAgent.indexOf("Windows 10") > -1;
          if (isWin10) return "pc";
        }
        return "other";
      }
      // alert("您的操作系统是：" + detectOS()); //判断是手机还是电脑，什么浏览器，手机操作系统
      let browser = (function () {
        let ua = navigator.userAgent;
        let isWindowsPhone = /(?:Windows Phone)/.test(ua);
        let isSymbian = /(?:SymbianOS)/.test(ua);
        let isAndroid = /(?:Android)/.test(ua);
        let isFireFox = /(?:Firefox)/.test(ua);
        let isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua));
        let isPhone = /(?:iPhone)/.test(ua) && !isTablet;
        let isChrome = /(?:Chrome|CriOS)/.test(ua);
        let isMobile = isWindowsPhone || isSymbian || isAndroid || isPhone;
        let isPc = !isMobile;
        return {
          isMobile: isMobile,
          isPc: isPc,
          isPhone: isPhone, //苹果
          isWindowsPhone: isWindowsPhone, //微软手机
          isSymbian: isSymbian, //班赛
          isAndroid: isAndroid, //安卓
          isFireFox: isFireFox,
          isChrome: isChrome,
          isTablet: isTablet, //平板
        };
      })();
      let visit_type; //手机or电脑or平板
      let userBrowser; //浏览器
      let userOs; //操作系统
      if (browser.isPc) {
        //电脑
        visit_type = "pc"; //如果是电脑则判断其操作系统
        userOs = detectOS();
        if (browser.isFireFox) {
          userBrowser = "火狐";
        } else if (browser.isChrome) {
          userBrowser = "谷歌";
        } else {
          userBrowser = "ie";
        }
      } else if (browser.isMobile) {
        //手机
        visit_type = "手机";
        userBrowser = "手机应用";
        if (browser.isPhone) {
          userOs = "手机";
        } else if (browser.isWindowsPhone) {
          userOs = "手机";
        } else if (browser.isSymbian) {
          userOs = "手机";
        } else if (browser.isAndroid) {
          userOs = "手机";
        } else {
          userOs = "手机";
        }
      } else if (browser.isTablet) {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      } else {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      }
      
      this.isMobile = browser.isMobile
    },
  },
  components: {
    Nav,
    Home,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.idea2 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .ideaCon {
    width: 89%;
    margin: 0 auto;
    padding: 0.64rem 0 0;
    .ideaConR {
      img {
        @include scss_whbb(100%, auto, none, 0.32rem);
      }
      .span {
        @include scss_fcwh(0.24rem, #121127, 0.44rem);
        margin: 0.16rem 0 0.12rem;
        opacity: 0.56;
        text-align: center;
      }
    }

    .details {
      width: 100%;

      .titles1 {
        @include scss_fch(0.28rem, #FFE600, 0.48rem);
        margin: 0 0 0.16rem;
      }

      .titles2 {
        @include scss_fcwh(0.48rem, #121127, 0.72rem);
        margin-bottom: 0.3rem;
      }

      p {
        @include scss_fch(0.32rem, rgba(18, 17, 39, 0.72), 0.57rem);
        margin-bottom: 0.60rem;
      }
    }
  }
}
.idea{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .ideaCon {
    @include scss_flex_h(row);
    justify-content: space-between;
    width: 77.9%;
    max-width: 1128px;
    margin: 0 auto;
    padding: 96px 0 93px;

    .ideaConR {
      width: 39.89%;
      img {
        @include scss_whbb(100%, auto, none, 16px);
        margin-top: 133px;
      }

      .span {
        @include scss_fcwh(12px, #FFE600, 22px);
        margin-top: 10px;
      }
    }

    .details {
      width: 50%;

      .titles1 {
        @include scss_fcwh(14px, #FFE600, 24px);
        margin-bottom: 4px;
      }

      .titles2 {
        @include scss_fcwh(40px, #121127, 62px);
        margin-bottom: 43px;
      }

      p {
        @include scss_fch(14px, #121127, 32px);
        margin-bottom: 30px;
      }
    }
  }
}
//导航
::v-deep .nav {
  i {
    @include scss_alb(0, -5px);
  }
  #idea{
    color: #2A0F23;
    font-weight: bold;
  }
}
//副导航
::v-deep .subNav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  // background: #121127 !important;
}
::v-deep .subNavShow{
  background: #fff;
}
::v-deep .subNavContent {
  span {
    // border-bottom: 0.5px solid rgba(255, 255, 255, 0.08);
    
    // color: #fff;
  }
  #idea{
    // color: #fff;
    font-weight: bold;
  }
}

#app {
  width: 100%;
  background: #fff;
}

</style>