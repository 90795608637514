<template>
  <!-- 搜索 -->
  <div id="index" :class="[isMobile?'search2':'search']">
    <div class="searchbg">
      <div class="searchCon relative wrap">
        <div class="searchL">
          <img src="../../website/assets/img/search/search1.png" alt=""/>
        </div>
        <div class="searchR">
          <div class="titles">如果你是一个广告主</div>
          <div class="sub">
            <p>将广告费直接支付给你潜在的客户，作为他们数据和时间的对价，彼此尊重的投放形式将使用户将更乐意看到你的推广信息，没有中间商赚差价。</p>
            <p>我们将为早期投放者提供高额补贴，几乎免费。</p>
          </div>
          <div class="btns relative h_v_center" @click="dow()">
            立即联系
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>
.search2{
  @include scss_wh(100%, auto);
  margin-bottom: 1.28rem;
  .searchbg{
    @include scss_whbb(100%, 100%,#F0FBF0);
    padding-bottom: 2.16rem;
    .searchCon{
      @include scss_wh(100%, 100%);
      @include scss_flex_h(column);
      .searchR{
        @include scss_wh(89.3%, auto);
        margin: 1.07rem auto 0;
        .titles{
          @include scss_fcwh(0.48rem, #121127, 0.72rem);
          margin-bottom: 0.4rem;
        }
        .sub{
          margin-bottom: 0.4rem;
          p{
            @include scss_fch(0.26rem, rgba(18, 17, 39, 0.56), 0.57rem);
          }
        }
        .btns{
          @include scss_whbb(100%, 1.04rem,#FFE600,0.10rem);
          @include scss_fcwh(0.32rem, #000, 0.46rem);
          cursor: pointer;
        }
      }
      .searchL{
        @include scss_wh(100%, auto);
        img{
          width: 100%;
        }
      }
    }
  }
}
.search{
  @include scss_wh(100%, 500px);
  margin-bottom: 96px;
  .searchbg{
    @include scss_whbb(91.38%, 100%,#F0FBF0,42px);
    max-width: 1216px;
    margin: 0 auto;
    .searchCon{
      @include scss_wh(88.6%, 100%);
      max-width: 1166px;
      margin-left: 3%;
      display: flex;
      justify-content: end;
      .searchR{
        @include scss_wh(38.9%, auto);
        margin: 96px 0 0 11.3%;
        .titles{
          @include scss_fcwh(40px, #121127, 56px);
          margin-bottom: 20px;
        }
        .sub{
          margin-bottom: 50px;
          p{
            @include scss_fch(14px, rgba(18, 17, 39, 0.56), 32px);
            margin-bottom: 30px;
          }
        }
        .btns{
          @include scss_whbb(128px, 52px,#FFE600,8px);
          @include scss_fcwh(16px, #000, 24px);
          cursor: pointer;
        }
      }
      .searchL{
        @include scss_wh(49.7%, auto);
        @include scss_alb(3.5%,0);
        img{
          width: 100%;
        }
      }
    }
  }
}
</style>