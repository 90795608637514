<template>
  <!-- 身份 -->
  <div id="index" :class="[isMobile?'identity2':'identity']">
    <div class="identitybg">
      <div class="identityCon relative wrap">
        <div class="identityL">
          <div class="titles">如果你是一个创作者</div>
          <div class="sub">
            <p>使用如尔App创建一个极简、带有明显身份特征的身份地址，用一个链接，聚合你的所有联系方式、社交媒体主页；部分信息还可以设置为付费查阅。</p>
            <p>我们将为早期KOL提供独特的身份地址和免费推广资源。</p>
          </div>
          <div class="btns relative h_v_center" @click="dow()">
            立即联系
          </div>
        </div>
        <div class="identityR">
          <img src="../../website/assets/img/identity/identity1.png" alt=""/>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
  },
  props: ["isMobile"],
};
</script>

<style lang="scss" scoped>
.identity2{
  @include scss_wh(100%, auto);
  margin-bottom: 1.28rem;
  .identitybg{
    @include scss_whbb(100%, 100%,#FFFCE5);
    padding-bottom: 2.16rem;
    .identityCon{
      @include scss_wh(100%, 100%);
      @include scss_flex_h(column-reverse);
      justify-content: flex-end;
      .identityR{
        @include scss_wh(100%, auto);
        margin: 0.94rem 0 0.87rem;
        img{
          width: 100%;
        }
      }
      .identityL{
        @include scss_wh(89.3%, auto);
        margin: 0 auto;
        .titles{
          @include scss_fcwh(0.48rem, #121127, 0.72rem);
          margin-bottom: 0.4rem;
        }
        .sub{
          margin-bottom: 0.4rem;
          p{
            @include scss_fch(0.26rem, rgba(18, 17, 39, 0.56), 0.57rem);
          }
        }
        .btns{
          @include scss_whbb(100%, 1.04rem,#FFE600,0.10rem);
          @include scss_fcwh(0.32rem, #000, 0.46rem);
          cursor: pointer;
        }
      }
    }
  }
}
.identity{
  @include scss_wh(100%, 500px);
  margin-bottom: 96px;
  .identitybg{
    @include scss_whbb(91.38%, 100%,#FFFCE5,32px);
    max-width: 1216px;
    margin: 0 auto;
    .identityCon{
      @include scss_wh(88.9%, 100%);
      max-width: 1170px;
      margin-left: 10.8%;
      @include scss_flex_h(row);
      .identityR{
        @include scss_wh(49.5%, auto);
        @include scss_arb(3.5%,0);
        img{
          width: 100%;
        }
      }
      .identityL{
        @include scss_wh(38.7%, auto);
        margin: 96px 11.6% 0 0;
        .titles{
          @include scss_fcwh(40px, #121127, 56px);
          margin-bottom: 20px;
        }
        .sub{
          margin-bottom: 25px;
          p{
            @include scss_fch(14px, rgba(18, 17, 39, 0.56), 32px);
            margin-bottom: 30px;
          }
        }
        .btns{
          @include scss_whbb(128px, 52px,#FFE600,8px);
          @include scss_fcwh(16px, #000, 23px);
          cursor: pointer;
        }
      }
    }
  }
}
</style>