<template>
  <!-- 首页 -->
  <div id="Home">
    <div class="v v_center" :class="[isMobile?'content2':'content']" v-show="isVideoEnd">
      <div class="subtitle">
        <span>Web3是一种上帝视角</span>
        <span>俯瞰苏格拉底的麦田</span>
      </div>
      <div class="replay relative h_v_center" @click="videoPlay()">再次播放</div>
    </div>
    <div class="homeMp4">
      <!-- pc -->
      <img class="videoEnd" v-show="isVideoEnd" src="https://sinianxingkong.oss-cn-beijing.aliyuncs.com/demo/end.jpg"
        alt="" />
      <video-player class="video-player vjs-custom-skin homeMp4 homeMp4Video" ref="videoPlayer" :playsinline="true"
        style="object-fit: fill" :options="playerOptions" :x5-video-player-fullscreen="true"
        @ended="onPlayerEnded($event)" @ready="playerReadied" v-if="!isMobile">
      </video-player>
    </div>

    <div class="videoClassBtn" v-if="is_videoClass">
      <div class="closeImg" @click="closeVideo()">
        <img src="https://sinianxingkong.oss-cn-beijing.aliyuncs.com/img/close.svg" alt="" />
      </div>
      <video controls autoplay src="https://img.imlinkapp.com/open/intro_2.mp4"></video>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      is_videoClass: false,//手机显示视频
      isVideoEnd: false,//播放完成
      isVideoReady: false,//视频加载完成
      playerOptions: {
        isSB: "",
        controls: false, //控制条
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "https://img.imlinkapp.com/open/intro_2.mp4",
          },
        ],
        poster: "https://sinianxingkong.oss-cn-beijing.aliyuncs.com/demo/end.jpg",
        // width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: false, //全屏按钮
        },
      },
    };
  },
  mounted() {
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        video.play();
        video.pause();
      },
      false
    );
    console.log(this.isMobile)
    
  },
  methods: {
    closeVideo() {
      this.is_videoClass = false
    },
    mobileVideoPlay() {
      this.is_videoClass = true
    },
    // 播放回调 视频系列 start
    videoPlay() {
      if(this.isMobile){
        this.mobileVideoPlay()
      }else{
        this.$refs.videoPlayer.player.play() // 播放
        this.isVideoEnd = false
      }
    },
    // 视频播完回调
    onPlayerEnded($event) {
      this.isVideoEnd = true
      console.log('视频播放完成----', this.isVideoEnd)
    },
    playerReadied() {
      console.log('加载完成')
      // this.isVideoReady = true
    },
    play(elm) {
      console.log("play", elm);
    },
    pause(e) {
      console.log("pause");
    },
    playend(e) {
      // alert("播放结束");
    },
    btnIOS() {
      window.open('https://apps.apple.com/cn/app/role/id1501321924')
    },
    btnAni() {
      window.open('https://sj.qq.com/appdetail/im.link.app')
    },
    dow() {
      if (this.isSB == "IOS") {
        this.btnIOS();
      } else {
        this.btnAni();
      }
    },
  },
  props: ["isMobile","isAudio"],
  watch: {
    isAudio(nv){
      this.playerOptions.muted = !nv
      this.isVideoEnd = false
    },
    isMobile(nv){
      if (this.isMobile) {
        this.isVideoEnd = true
      }else{
        this.isVideoEnd = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.videoClassBtn {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);

  video {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .closeImg {
    background: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 999999;
    padding: 10px;
    right: 3%;
    top: 13%;
    line-height: initial;
  }
}

::v-deep .vjs-progress-control .vjs-control .video-js .vjs-progress-control {
  opacity: 0 !important;
  display: none !important;
}

::v-deep .vjs-tech {
  object-fit: cover !important;
  width: 100vw !important;
  height: 100vh !important;
  min-height: 100vh !important;
}
::v-deep .vjs-tech:focus {
  outline:none;
}

::v-deep .vjs-poster {
  background-size: cover !important;
  width:100vw !important;
  height: 100vh !important;
}

.posterImg {
  width: auto !important;
  height: 100vh !important;
}

#Home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden !important;

  .homeMp4Img {
    @include scss_wh(100%,100%);
    margin-top: 43px;
    img{
      @include scss_wh(100%,auto);
    }
    // 黑色蒙版
    .asd {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
    //视频背景
  }

  .homeMp4Video {
    display: flex !important;
  }

  .homeMp4 {
    overflow: hidden;
    // display: flex;
    // justify-content: center;
    width: 100%;
    height: 100%;
    // object-fit: fill;
    @include scss_alt;
    z-index: 2 !important;
    background-repeat: repeat;

    img {
      object-fit: cover;
      position: absolute;
      height: 100%;
      top: 0;
      left: -80%;
      // flex: 0 0 100vw;
    }

    .videoEnd {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
  }

  .btn {
    display: none;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 122px;
    height: 42px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  .content2{
    @include scss_wh(100%,1.44rem);
    @include scss_alt(50%,50%);
    transform: translate(-50%, -50%);
    z-index: 3;
    .subtitle {
      span{
        font-weight: 700;
        @include scss_fch(0.48rem, #fff,0.72rem);
        display: block;
        text-align: center;
      }
    }
    .replay {
      margin-top: 0.4rem;
      @include scss_whbb(89.3%,1.04rem,#FFE600,0.16rem);
      cursor: pointer;
      font-weight: 600;
      @include scss_fch(0.32rem, #000,1.04rem);
    }
  }
  .content {
    height: 122px;
    @include scss_alt(50%,50%);
    transform: translate(-50%, -50%);
    z-index: 3;

    .subtitle {
      span{
        font-weight: 700;
        @include scss_fch(40px, #fff,56px);
        letter-spacing: 10px;
        display: block;
        text-align: center;
      }
    }

    .replay {
      margin-top: 13px;
      @include scss_whbb(128px,52px,#FFE600,8px);
      cursor: pointer;
      font-weight: 600;
      @include scss_fch(16px, #000,52px);
    }
  }
}

.IOS,
.Ani {
  display: none !important;
}

.btnIOS:hover+.IOS {
  display: flex !important;
}

.btnAni:hover+.Ani {
  display: flex !important;
}
</style>
