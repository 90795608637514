import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "../views/index";
import idea from "../views/idea/idea";
import nfcChip from "../views/recharge/nfcChip";
import searchList from "../views/searchList/searchList";
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location){
    return originalReplace.call(this , location).catch(err=>err)
}
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: 'index',
        component: index,
    },
    {
        path: "/view",
        name: 'view',
        component: idea,
    },
    {
        path: "/chip",
        name: 'chip',
        component: nfcChip,
    },
    {
        path: "/search",
        name: 'search',
        component: searchList,
    },
];

const router = new VueRouter({
    mode: 'history', //  history
    base: process.env.BASE_URL,
    routes
});
// let beforeHeight, afterHeight;
router.beforeEach((to,from,next)=>{
    if(to.meta.requireAuth){ // 需要登录权限
        next();
    }else{
        next();
    }
});
export default router;
