<template>
  <div id="app">
    <Nav :isMobile="isMobile" :types="1" :isScrollTop="isScrollTop" />
    <!-- 数据-->
    <div class="relativeoh" :class="[isMobile ? 'recharge' : 'recharge2']">
      <div class="rechargeTop v">
        <div class="title11">
          <span class="h_center">购买最In</span>
          <span class="h_center">的身份芯片</span>
        </div>
        <div class="title12">
          <span class="h_center">碰一碰</span><i v-if="!isMobile">,</i> 
          <span class="h_center">交朋友</span>
        </div>
        <span class="h_center title2">线下使用手机碰触芯片，即可打开对方的身份地址，查阅最详细的信息</span>
      </div>
      <div class="swiper-container banner" v-show="isMobile">
        <div class="swiper-wrapper">
          
          <div class="swiper-slide">
            <img src="../../website/assets/img/nfc1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../website/assets/img/nfc2.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../website/assets/img/nfc3.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../website/assets/img/nfc4.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../website/assets/img/nfc5.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <video class="video" muted="muted"  controls src="https://img.imlinkapp.com/%E5%A6%82%E5%B0%94%E7%A4%BE%E4%BA%A4%E8%8A%AF%E7%89%87%E8%AE%B2%E8%A7%A3.mp4"></video>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination h_v_center"></div>
      </div>
      <div class="rechargeCon details">
        <div class="currNfc relative h">
          <div class="currL" v-if="!isMobile">
            <div class="videoCon relative nfcImg"  v-if="currDetailIdx == 0">
              <video class="video" autoplay muted loop controls src="https://img.imlinkapp.com/%E5%A6%82%E5%B0%94%E7%A4%BE%E4%BA%A4%E8%8A%AF%E7%89%87%E8%AE%B2%E8%A7%A3.mp4"></video>
            </div>
            <img class="nfcImg" :src="currItems" v-else/>
            <div class="detailSrcUl">
              <img src="../../website/assets/img/nfc6.jpg" :class="{ 'active': currDetailIdx == 0 }" @click="detailSrcClick(0)" />
              <img src="../../website/assets/img/nfc1.jpg" :class="{ 'active': currDetailIdx == 1 }" @click="detailSrcClick(1)" />
              <img src="../../website/assets/img/nfc2.jpg" :class="{ 'active': currDetailIdx == 2 }" @click="detailSrcClick(2)" />
              <img src="../../website/assets/img/nfc3.jpg" :class="{ 'active': currDetailIdx == 3 }" @click="detailSrcClick(3)" />
              <img src="../../website/assets/img/nfc4.jpg" :class="{ 'active': currDetailIdx == 4 }" @click="detailSrcClick(4)" />
              <img src="../../website/assets/img/nfc5.jpg" :class="{ 'active': currDetailIdx == 5 }" @click="detailSrcClick(5)" />
            </div>
          </div>
          <div class="currR v">
            <div class="currCon">
              <img class="nfcImg" :src="currItems" v-if="isMobile" />
              <div class="currConR">
                <div class="proNames">如尔身份芯片</div>
                <div class="pricesCon h end">
                  <span class="icons">￥</span>
                  <span class="prices">29.9元</span>
                </div>
              </div>
            </div>
            <div class="typeTitle">选择款式</div>
            <div class="grid h wrap">
              <div class="h h_v_center gridItem" :class="{ 'active': currIdx == index }" v-for="(item, index) in list"
                :key="index" @click="liClick(index)">
                <img :src="item.imgSrc" />
                <span class="names">{{ item.names }}</span>
              </div>
            </div>
            <div class="addressCon" v-if="!isMobile">
              <div class="titles">收货信息</div>
              <div class="ul">
                <div class="li h v_center relative">
                  <label>姓　　名：</label><input type="text" v-model="names" maxlength="20"/>
                  <label class="margin_l20">联系方式：</label><input type="number" v-model="phone" @input="telInput()" maxlength="11"/>
                </div>
                <div class="li h relative">
                  <label>联系地址：</label><textarea v-model="address" maxlength="100"></textarea>
                </div>
              </div>
              <div class="btns h_v_center" @click="payClick()">微信支付</div>
            </div>
          </div>
        </div>
        <div class="addressCon" v-if="isMobile">
          <div class="titles">收货信息</div>
          <div class="ul">
            <div class="li h v_center relative">
              <label>姓　　名：</label><input type="text" v-model="names" maxlength="2"/>
            </div>
            <div class="li h v_center relative">
              <label>联系方式：</label><input type="number" v-model="phone" @input="telInput()" maxlength="11"/>
            </div>
            <div class="li h relative">
              <label>联系地址：</label><textarea v-model="address" maxlength="100"></textarea>
            </div>
          </div>
          <div class="btns h_v_center" @click="payClick()">微信支付</div>
        </div>
        <div class="aboutCon">
          <div class="titles" v-if="isMobile">关于如尔身份芯片</div>
          <div class="about">
            <p>
              如尔身份芯片是配合如尔App使用的一款NFC工具，可以将您的身份地址（网址）信息写入芯片，其他人使用带NFC功能的手机触碰芯片即可打开您的身份地址，查看您授权到该身份上的各种信息，包括索引简介、联系方式、网页链接等。适合线下社交、商务场景适用。
            </p>
            <p>您还可以自主设定部分信息的价格，他人可自愿付费查看，您将获得收入的绝大部分。</p>
          </div>
        </div>
        <div class="detailsImgCon relative" v-if="isMobile">
          <div class="titles">使用流程</div>
          <video poster="https://img.imlinkapp.com/nfcposter.jpg" class="video" muted="muted"  controls src="https://img.imlinkapp.com/xpsyjc.mp4"></video>
          <div class="titles">商品详情</div>
          <img src="../../website/assets/img/detailsImg0.png" alt="" />
        </div>

        <van-tabs v-model="tabactive" v-if="!isMobile">
          <van-tab title="使用流程">
            <video poster="https://img.imlinkapp.com/nfcposter.jpg" class="video" muted="muted"  controls src="https://img.imlinkapp.com/xpsyjc.mp4"></video>
            <img src="../../website/assets/img/detailsImg1.png" alt="" />
          </van-tab>
          <van-tab title="商品详情">
            <img src="../../website/assets/img/detailsImg2.png" alt="" />
          </van-tab>
          <van-tab title="购买须知">
            <img src="../../website/assets/img/detailsImg3.png" alt="" />
          </van-tab>
        </van-tabs>
      </div>
      <Footer :isMobile="isMobile" />
    </div>
  </div>
</template>
<script>
import Nav from "../nav/Nav.vue";
import Swiper from "swiper";
import imgSrc1 from "../../website/assets/img/style1.png";
import imgSrc2 from "../../website/assets/img/style2.png";
import imgSrc3 from "../../website/assets/img/style3.png";
import imgSrc4 from "../../website/assets/img/style4.png";
import imgSrc5 from "../../website/assets/img/style5.png";
import imgSrc6 from "../../website/assets/img/style6.png";
import imgSrc7 from "../../website/assets/img/style7.png";
import imgSrc8 from "../../website/assets/img/style8.png";
import imgSrc9 from "../../website/assets/img/style9.png";
import imgSrc10 from "../../website/assets/img/style10.png";
import imgSrc11 from "../../website/assets/img/style11.png";
import imgSrc12 from "../../website/assets/img/style12.png";

import detailSrc1 from "../../website/assets/img/nfc1.jpg";
import detailSrc2 from "../../website/assets/img/nfc2.jpg";
import detailSrc3 from "../../website/assets/img/nfc3.jpg";
import detailSrc4 from "../../website/assets/img/nfc4.jpg";
import detailSrc5 from "../../website/assets/img/nfc5.jpg";
import Footer from "../footer/Footer.vue";
export default {
  data() {
    return {
      tabactive:0,
      isMobile: false,
      isImg1: false,
      isImg2: true,
      isImgBtn: false,
      isImgBtn1: true,
      isImgBtn2: false,
      names: '',
      phone: '',
      address: '',
      currNum: 1,
      currIdx: 0,
      currItems: {},
      totalpricesCon: 29.9,
      list: [
        {
          'names': '暴富如尔所愿',
          'imgSrc': imgSrc1
        }, {
          'names': '明天如尔所愿',
          'imgSrc': imgSrc6
        },{
          'names': '脱单如尔所愿',
          'imgSrc': imgSrc4
        }, 
        {
          'names': '变瘦如尔所愿',
          'imgSrc': imgSrc5
        }, 
        {
          'names': '上岸如尔所愿',
          'imgSrc': imgSrc7
        },
        
         {
          'names': "红色Let's Role",
          'imgSrc': imgSrc8
        }, {
          'names': "黄色Let's Role",
          'imgSrc': imgSrc9
        }, {
          'names': "粉色Let's Role",
          'imgSrc': imgSrc12
        },{
          'names': "蓝色Let's Role",
          'imgSrc': imgSrc11
        },  {
          'names': "绿色Let's Role",
          'imgSrc': imgSrc10
        }, 
        {
          'names': '小男孩',
          'imgSrc': imgSrc3
        },{
          'names': '小女孩',
          'imgSrc': imgSrc2
        },
      ],
      detailSrc:['https://img.imlinkapp.com/%E5%A6%82%E5%B0%94%E7%A4%BE%E4%BA%A4%E8%8A%AF%E7%89%87%E8%AE%B2%E8%A7%A3.mp4',detailSrc1,detailSrc2,detailSrc3,detailSrc4,detailSrc5],
      currDetailIdx:0,
      isScrollTop: false,
      isSwiper:false,
    };
  },
  mounted() {
    this.userAgent()
    console.log(this.isMobile);
    if(this.isMobile){
      this.isSwiper = true
      this.mySwiperfunc();
      this.currItems = this.list[this.currIdx].imgSrc
    }else{
      this.currItems = this.detailSrc[this.currIdx]
    }
    // 监听页面滚动事件
    if (!this.isMobile) {
      window.addEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed() {
    this.isSwiper = false
    //离开这个界面之后，删除滚动事件，不然容易除bug
    if (!this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    telInput(){
      if(this.phone.length>11){
        this.phone=this.phone.slice(0,11)
      }
    },
    buyClick(links){
      window.open(links)
    },
    //Swiper组件
    mySwiperfunc() {
      this.mySwiper = new Swiper(".swiper-container", {
        observer: true, //开启动态检查器，监测swiper和slide
        loop: false,
        direction: "horizontal",
        // 鼠标可滑动
        mousewheelControl: false,
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        }
      });
    },
    payClick() {
      if (!this.names) {
        this.$toast('请输入收件人姓名')
      } else if (!(/^1[3,4,5,6,7,8,9][0-9]{9}$/.test(this.phone))) {
        this.$toast('请输入正确的手机号')
      } else if (!this.address) {
        this.$toast('请输入收件地址')
      }else{
        this.$toast.loading({
        message: '微信支付待开通...',
        forbidClick: true,
      });
      }
    },
    reduce() {
      if (this.currNum > 1) {
        this.currNum--
        this.totalpricesCon = (this.currNum * (29.9 * 10)) / 10
      }
    },
    add() {
      this.currNum++
      this.totalpricesCon = (this.currNum * (29.9 * 10)) / 10
    },
    goBuy() {
      this.isBuy = true
    },
    liClick(index) {
      this.currIdx = index
      this.currItems = this.list[this.currIdx].imgSrc
      this.currDetailIdx = -1
    },
    detailSrcClick(index){
      this.currDetailIdx = index
      this.currItems = this.detailSrc[index]
    },

    handleScroll() {
      this.$nextTick(() => {
        if (document.documentElement.scrollTop > 100) {
          this.isScrollTop = true;
        } else {
          this.isScrollTop = false;
        }
      });
    },
    userAgent() {
      //如果是电脑，则判断电脑的操作系统
      function detectOS() {
        let sUserAgent = navigator.userAgent;
        let isWin =
          navigator.platform == "Win32" || navigator.platform == "Windows";
        let isMac =
          navigator.platform == "Mac68K" ||
          navigator.platform == "MacPPC" ||
          navigator.platform == "Macintosh" ||
          navigator.platform == "MacIntel";
        if (isMac) return "pc";
        let isUnix = navigator.platform == "X11" && !isWin && !isMac;
        if (isUnix) return "pc";
        let isLinux = String(navigator.platform).indexOf("Linux") > -1;
        if (isLinux) return "pc";
        if (isWin) {
          let isWin2K =
            sUserAgent.indexOf("Windows NT 5.0") > -1 ||
            sUserAgent.indexOf("Windows 2000") > -1;
          if (isWin2K) return "pc";
          let isWinXP =
            sUserAgent.indexOf("Windows NT 5.1") > -1 ||
            sUserAgent.indexOf("Windows XP") > -1;
          if (isWinXP) return "pc";
          let isWin2003 =
            sUserAgent.indexOf("Windows NT 5.2") > -1 ||
            sUserAgent.indexOf("Windows 2003") > -1;
          if (isWin2003) return "pc";
          let isWinVista =
            sUserAgent.indexOf("Windows NT 6.0") > -1 ||
            sUserAgent.indexOf("Windows Vista") > -1;
          if (isWinVista) return "pc";
          let isWin7 =
            sUserAgent.indexOf("Windows NT 6.1") > -1 ||
            sUserAgent.indexOf("Windows 7") > -1;
          if (isWin7) return "pc";
          let isWin8 =
            sUserAgent.indexOf("Windows NT 8.0") > -1 ||
            sUserAgent.indexOf("Windows 8") > -1;
          if (isWin8) return "pc";
          let isWin10 =
            sUserAgent.indexOf("Windows NT 10.0") > -1 ||
            sUserAgent.indexOf("Windows 10") > -1;
          if (isWin10) return "pc";
        }
        return "other";
      }
      // alert("您的操作系统是：" + detectOS()); //判断是手机还是电脑，什么浏览器，手机操作系统
      let browser = (function () {
        let ua = navigator.userAgent;
        let isWindowsPhone = /(?:Windows Phone)/.test(ua);
        let isSymbian = /(?:SymbianOS)/.test(ua);
        let isAndroid = /(?:Android)/.test(ua);
        let isFireFox = /(?:Firefox)/.test(ua);
        let isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua));
        let isPhone = /(?:iPhone)/.test(ua) && !isTablet;
        let isChrome = /(?:Chrome|CriOS)/.test(ua);
        let isMobile = isWindowsPhone || isSymbian || isAndroid || isPhone;
        let isPc = !isMobile;
        return {
          isMobile: isMobile,
          isPc: isPc,
          isPhone: isPhone, //苹果
          isWindowsPhone: isWindowsPhone, //微软手机
          isSymbian: isSymbian, //班赛
          isAndroid: isAndroid, //安卓
          isFireFox: isFireFox,
          isChrome: isChrome,
          isTablet: isTablet, //平板
        };
      })();
      let visit_type; //手机or电脑or平板
      let userBrowser; //浏览器
      let userOs; //操作系统
      if (browser.isPc) {
        //电脑
        visit_type = "pc"; //如果是电脑则判断其操作系统
        userOs = detectOS();
        if (browser.isFireFox) {
          userBrowser = "火狐";
        } else if (browser.isChrome) {
          userBrowser = "谷歌";
        } else {
          userBrowser = "ie";
        }
      } else if (browser.isMobile) {
        //手机
        visit_type = "手机";
        userBrowser = "手机应用";
        if (browser.isPhone) {
          userOs = "手机";
        } else if (browser.isWindowsPhone) {
          userOs = "手机";
        } else if (browser.isSymbian) {
          userOs = "手机";
        } else if (browser.isAndroid) {
          userOs = "手机";
        } else {
          userOs = "手机";
        }
      } else if (browser.isTablet) {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      } else {
        visit_type = "平板";
        userOs = "平板";
        userBrowser = "平板";
      }
      this.isMobile = browser.isMobile
    },
  },
  components: {
    Nav,
    Footer,
  },
};
</script>
<style lang="scss" scoped>

.recharge2 {
  @include scss_wh(100%, auto);
  background: #fff;
  .rechargeTop {
    @include scss_wh(78.3%, auto);
    max-width: 1128px;
    margin: 135px auto 78px;
    .title11,.title12 {
      @include scss_fcwh(40px, #121127, 56px);
      display: block;
      text-align: center;
      span{
        display: inline-block;
      }
    }
    .title12{
      margin-bottom: 20px;
      i{
        padding: 0 20px 0 10px;
      }
    }
    .title2 {
      @include scss_fch(14px, rgba(18, 17, 39, 0.72), 32px);
      text-align: center;
    }
  }
  .rechargeCon {
    width: 1000px;
    margin: 0 auto;
    ::v-deep .van-tabs{
      .van-tabs__wrap{
        height: 54px;
        border-bottom: solid 1px #E2E2E2;
      }
      .van-tab{
        flex: initial;
        padding: 0;
        margin-right: 57px;
        font-size: 20px;
        line-height: 27px;
        color: #333;
      }
      .van-tab--active{
        font-size: 24px;
        line-height: 33px;
        font-weight: bold;
      }
      .van-tabs__line{
        background: #FFE600;
      }
      .van-tabs__content{
        padding-top: 32px;
        position: relative;
        overflow: hidden;
        .video{
          width: 100%;
          height: auto;
        }
      }
    }
    .buyCon {
      margin: 27px 0 0;
      .btns {
        @include scss_whbb(auto, 52px, #FFE600, 8px);
        @include scss_fcwh(16px, #000, 23px);
        padding: 14px 123px;
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .useCon {
      width: 88.6%;
      margin: 0 auto;
      .titles {
        @include scss_fcwh(24px, #121127, 34px);
        text-align: center;
        margin-bottom: 8px;
      }
    }
    .aboutCon {
      border-top: solid 1px #E2E2E5;
      padding: 50px 0 0;
      .titles {
        @include scss_fcwh(24px, #121127, 34px);
        margin-bottom: 15px;
        text-align: center;
      }
      .about {
        @include scss_fch(14px, rgba(18, 17, 39, 0.72), 32px);
        p {
          margin-bottom: 30px;
        }
      }
    }
    .currNfc {
      margin: 0 0 60px;
      justify-content: space-between;
      .currL{
        @include scss_wh(400px, auto);
        margin-top: 7px;
        .detailSrcUl{
          img{
            @include scss_whbb(60px, 60px,none,8px);
            margin-right: 8px;
          }
          img:last-child{
            margin-right: 0;
          }
        }
        .nfcImg {
          @include scss_whbb(400px, 400px,none,16px);
          margin-bottom: 26px;
          video{
            @include scss_whbb(400px, 400px,none,16px);
          }
          .screen{
            position: absolute;
            right: 5px;
            bottom: 5px;
            z-index: 1;
            display: none;
          }
        }
        .nfcImg:hover{
          .screen{
            display: block;
          }
        }
        .videoCon{
          box-shadow: inset 0 0 20px 20px #F6F6F7;
        }
      }
      .currR{
        width: 560px;
        .pricesCon {
          display: inline;
          .icons {
            @include scss_fcwh(20px, #FF5733);
            margin-bottom: 1px;
          }
          .prices {
            @include scss_fcwh(24px, #FF5733);
          }
        }
        .proNames {
          @include scss_fcwh(40px, #121127, 56px);
          margin-bottom: 15px;
        }
        .proTitles{
          @include scss_fcwh(14px, #FFE600, 24px);
          margin-bottom: 10px;
        }
      }
    }
    .grid {
      .gridItem {
        margin: 0 10px 10px 0;
        padding: 4px 8px 4px 8px;
        border: 1px solid #F6F6F7;
        @include scss_whbb(auto, 32px, #F6F6F7, 6px);
        .names {
          @include scss_fch(14px, #201F37, 26px);
          font-weight: normal;
        }
        img {
          @include scss_wh(24px, 24px);
          margin-right: 8px;
        }
      }
      .active {
        border: 1px solid #FFEB3B;
      }
    }
    .typeTitle {
      @include scss_fcwh(14px, #000, 35px);
    }
    .tips {
      @include scss_fcwh(14px, #121127, 34px);
      margin-bottom: 100px;
      text-align: center;
      a {
        color: #0609FF;
      }
    }
    .addressCon{
      .titles {
        @include scss_fcwh(16px, #000, 22px);
        margin-bottom: 12px;
      }
      .ul{
        margin-bottom: 30px;
        .li{
          margin-bottom: 15px;
          label{
            @include scss_fch(14px, #666, 32px);
          }
          .margin_l20{
            margin-left: 20px;
          }
          input{
            @include scss_whbb(200px, 32px, #F6F6F6, 6px);
            @include scss_fch(14px, #333, 32px);
            padding: 0 12px;
          }
          textarea{
            @include scss_whbb(auto, 32px, #F6F6F6, 6px);
            @include scss_fch(14px, #333, 32px);
            padding: 2px 12px;
            resize: none;
            overflow: hidden;
            flex: 1;
          }
        }
      }
      .btns {
        @include scss_whbb(300px, 48px, #FFE600, 24px);
        @include scss_fch(16px, #000, 48px);
      }
    }
  }
  .useList {
    .swiper-wrapper {
      margin-top: 4px;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: center;
      .swiper-slide{
        width: 40%;
        margin-bottom: 8px;
      }
      .swiper-slide:nth-child(2n){
        margin-left: 30px;
      }
    }
    .swiper-pagination {
      display: none;
    }
  }
}
.recharge {
  @include scss_wh(100%, auto);
  background: #fff;
  .rechargeTop {
    @include scss_wh(86.7%, auto);
    margin: 3.02rem auto 2rem;
    .title11,.title12 {
      @include scss_fcwh(1rem, #121127, 1.44rem);
    }
    .title12{
      margin-bottom: 0.24rem;
    }
    .title2 {
      @include scss_fch(0.3rem, #121127, 0.48rem);
      text-align: center;
    }
  }
  .rechargeCon {
    @include scss_wh(89.3%, auto);
    margin: 0.29rem auto 0.44rem;
    .addressCon{
      .titles {
        @include scss_fcwh(0.3rem, #000, 0.43rem);
        margin-bottom: 0.24rem;
      }
      .ul{
        @include scss_whbb(100%, auto, #F6F6F6, 0.08rem);
        padding: 0.32rem 0.23rem 0.08rem;
        margin-bottom: 0.56rem;
        .li{
          margin-bottom: 0.24rem;
          label{
            @include scss_wh(1.64rem, 0.72rem);
            @include scss_fch(0.28rem, #666, 0.72rem);
          }
          input{
            @include scss_whbb(calc(100% - 1.64rem), 0.72rem, #fff, 0.12rem);
            @include scss_fch(0.28rem, #333, 0.72rem);
            padding: 0 0.24rem;
          }
          textarea{
            @include scss_whbb(calc(100% - 1.64rem), 1.53rem, #fff, 0.12rem);
            @include scss_fch(0.28rem, #333, 0.42rem);
            padding: 0.2rem 0.24rem;
            resize: none;
          }
        }
      }
      .btns {
        @include scss_whbb(100%, 0.88rem, #FFE600, 0.44rem);
        @include scss_fch(0.32rem, #000, 0.46rem);
        padding: 0 0.3rem;
        margin-bottom: 0.61rem;
      }
    }
    .buyCon {
      margin: 0.16rem 0 0.44rem;
      justify-content: space-between;
      .btns {
        @include scss_whbb(100%, 0.88rem, #FFE600, 0.16rem);
        @include scss_fcwh(0.32rem, #000, 0.46rem);
        padding: 0 0.3rem;
      }
    }
    .detailsImgCon {
      .titles {
        @include scss_fcwh(0.28rem, #121127, 0.4rem);
        margin-bottom: 0.32rem;
      }
      .video{
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
      }
    }
    .aboutCon {
      .titles {
        @include scss_fcwh(0.28rem, #121127, 0.4rem);
        margin-bottom: 0.24rem;
      }
      .about {
        @include scss_fch(0.26rem, rgba(18, 17, 39, 0.72), 0.56rem);
        p {
          margin-bottom: 0.5rem;
        }
      }
    }
    .currNfc {
      margin-bottom: 0.26rem;
      .currCon{
        @include scss_flex_h(row);
        margin-bottom: 0.19rem;
      }
      .pricesCon {
        .icons {
          @include scss_fcwh(0.24rem, #FF5733);
          margin-bottom: 0.13rem;
        }
        .prices {
          @include scss_fcwh(0.4rem, #FF5733, 0.64rem);
        }
      }
      .proNames {
        @include scss_fcwh(0.34rem, #121127, 0.49rem);
        margin-top: 0.03rem;
      }
      .nfcImg {
        @include scss_wh(1.2rem, 1.2rem);
        margin-right: 0.3rem;
      }
    }
    .grid {
      .gridItem {
        margin: 0 0.32rem 0.24rem 0;
        padding-right: 0.16rem;
        border: 0.02rem solid #F6F6F7;
        @include scss_whbb(auto, 0.64rem, #F6F6F7, 0.12rem);
        .names {
          @include scss_fcwh(0.26rem, #201F37, 0.37rem);
        }
        img {
          @include scss_wh(0.56rem, 0.56rem);
          margin-right: 0.16rem;
        }
      }
      .active {
        border: 0.02rem solid #FFEB3B;
      }
    }
    .typeTitle {
      @include scss_fcwh(0.3rem, #000, 0.43rem);
      margin-bottom: 0.24rem;
    }
    .tips {
      @include scss_fcwh(0.28rem, #121127, 0.66rem);
      a {
        color: #0609FF;
      }
    }
  }
  .swiper-container {
    width: 100% !important;
    .swiper-wrapper {
      .swiper-slide {
        width: 100vw !important;
        height: 100vw;
        background-color: #fff;
        text-align: center;
        video{
          @include scss_wh(100%, 100%);
        }
        img {
          @include scss_wh(100%, 100%);
          object-fit: cover;
        }
      }
      .swiper-slide:first-child{
        box-shadow: inset 0 0 20px 20px #f6f6f7;
      }
    }
  }
  .banner {
    .swiper-pagination {
      left: auto;
      @include scss_arb(0.24rem, 0.66rem);
      @include scss_whbb(0.78rem, 0.4rem, rgba(0, 0, 0, 0.5), 0.2rem);
      @include scss_fch(0.22rem, #fff, 0.4rem);
      letter-spacing: 1px;
    }
  }
  .useList {
    .swiper-wrapper {
      margin-top: 0.4rem;
    }
    .swiper-pagination {
      left: auto;
      height: auto;
      text-align: right;
      @include scss_art(0, 0);
      font-weight: bold;
    }
  }
}
img {
  max-width: 100%;
}

//导航
::v-deep .nav {
  i {
    @include scss_alb(250px, -5px);
  }
  #recharge {
    font-weight: 600;
  }
}
::v-deep .subNavContent{
  #recharge {
    font-weight: 600;
  }
}
//swiper
#app {
  width: 100%;
  background: #fff;
}
.flex-auto {
  flex: 1 1 auto;
}
.shrink-0 {
  flex-shrink: 0;
}</style>