import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'swiper/css/swiper.min.css'
import 'swiper/js/swiper.min'
import '@/website/assets/css/public/reset.css'
import '@/utils/rem.js'; // 初始化css
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
import lottie from 'vue-lottie'
Vue.component('lottie', lottie)

import waterfall from 'vue-waterfall2'

Vue.use(waterfall)

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
   router,
   render: h => h(App),
}).$mount('#app')
